import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ListContent } from '.'

import { ReactComponent as DisabledIcon } from '../assets/disabled-icon.svg'
import { ReactComponent as ActiveIconBtn } from '../assets/active-icon.svg'
import { ReactComponent as EditIcon } from '../assets/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../assets/delete-icon.svg'
import Modal from './Modal'
import { UserLoadingTypes } from '../redux/reducers/user/types'
import UserService from '../services/user'

import {
  TABLE_LIST_ITEM_VALUE,
  TABLE_LIST_ITEM_VALUE__ID,
  USER_ROLES,
} from '../utils/consts'

import { useActions } from '../hooks/useActions'

const Users = ({
  getData,
  createData,
  updateData,
  deleteData,
  roles = Object.keys(USER_ROLES),
}) => {
  const {
    user: { users, count: totalCount },
  } = useSelector(({ user, auth }) => {
    return { user, auth }
  })

  const { loadingStart, loadingEnd, userSetEnabled } = useActions()

  const [modalActive, setModalActive] = useState()
  const [userData, setUserData] = useState({
    login: '',
    phone: '',
    email: '',
    password: '',
    role: '',
    id: -1,
  })

  const [deleteCandidateUser, setDeleteCandidateUser] = useState({})

  const [confirmDeleteUserModalActive, setConfirmDeleteUserModalActive] =
    useState(false)

  const renderItemValue = item => {
    return (
      <div className='table-list_row'>
        <div className={TABLE_LIST_ITEM_VALUE__ID}>{item.id}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>{item.login}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>{item.phone}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>{item.email}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>{USER_ROLES[item.role]}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>
          <button
            className='table-list_item-btn'
            onClick={async () => {
              const newData = { ...item, enabled: !item.enabled }
              userSetEnabled(newData)
            }}
          >
            {item.enabled ? <DisabledIcon /> : <ActiveIconBtn />}
          </button>
          <button
            className='table-list_item-btn'
            onClick={async () => {
              try {
                loadingStart(UserLoadingTypes.GET_ONE)

                const { data: user } = await UserService.getUserById(item.id)

                setUserData({ ...user })
                setModalActive(true)
              } catch (e) {
              } finally {
                loadingEnd(UserLoadingTypes.GET_ONE)
              }
            }}
          >
            <EditIcon />
          </button>
          <button
            className='table-list_item-btn'
            onClick={() => {
              setDeleteCandidateUser(item)
              setConfirmDeleteUserModalActive(true)
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    )
  }

  const renderUserModalForm = () => {
    if (userData.id === -1) return true
    return false
  }

  const formHandle = e => {
    e.preventDefault()
    if (renderUserModalForm()) {
      createData(userData)
      setModalActive(false)
      return
    }

    updateData(userData)
    setModalActive(false)
  }

  const createBtn = () => {
    setUserData({
      login: '',
      phone: '',
      email: '',
      password: '',
      role: '',
      id: -1,
    })
    setModalActive(true)
  }

  useEffect(() => {
    ;(async () => {
      getData()
    })()
  }, [getData])

  const { login, phone, email, password, role } = userData

  return (
    <div className='company-users'>
      <ListContent
        title={'Company users'}
        columnTitles={['id', 'login', 'phone', 'email', 'role', 'actions']}
        renderItemValue={renderItemValue}
        data={users}
        createBtn={createBtn}
      />

      <Modal
        active={modalActive}
        setActive={setModalActive}
        title={renderUserModalForm() ? 'Create user' : 'Update user'}
      >
        <form className='company-users-form modal-form' onSubmit={formHandle}>
          <label>
            <div>Login</div>
            <input
              type='text'
              value={login}
              onChange={e => {
                setUserData(oldS => ({
                  ...oldS,
                  login: e.target.value,
                }))
              }}
            />
          </label>
          <label>
            <div>Phone</div>
            <input
              type='text'
              value={phone}
              onChange={e => {
                setUserData(oldS => ({
                  ...oldS,
                  phone: e.target.value,
                }))
              }}
            />
          </label>
          <label>
            <div>Email</div>
            <input
              type='text'
              value={email}
              onChange={e => {
                setUserData(oldS => ({
                  ...oldS,
                  email: e.target.value,
                }))
              }}
            />
          </label>
          <label>
            <div>Password</div>
            <input
              type='text'
              value={password}
              onChange={e => {
                setUserData(oldS => ({
                  ...oldS,
                  password: e.target.value,
                }))
              }}
            />
          </label>
          {roles && (
            <label>
              <div>Role</div>
              <select
                value={role}
                onChange={e => {
                  setUserData(oldS => ({
                    ...oldS,
                    role: +e.target.value,
                  }))
                }}
              >
                <option value={''}>Not selected</option>
                {roles.map(role => {
                  return (
                    <option key={role} value={role}>
                      {USER_ROLES[role]}
                    </option>
                  )
                })}
              </select>
            </label>
          )}
          <button className='company-users-form_btn admin-btn'>Save</button>
        </form>
      </Modal>
      <Modal
        confirm
        active={confirmDeleteUserModalActive}
        setActive={setConfirmDeleteUserModalActive}
        title={`Delete User`}
        footer={
          <div className='company-users_delete-user-footer'>
            <div className='modal-footer-buttons'>
              <button
                className='modal-footer-buttons_btn admin-btn'
                onClick={() => {
                  deleteData(deleteCandidateUser.id)
                  setConfirmDeleteUserModalActive(false)
                }}
              >
                Ok
              </button>
              <button
                className='modal-footer-buttons_btn admin-btn'
                onClick={() => {
                  setConfirmDeleteUserModalActive(false)
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        }
      >
        <div className='company-users_delete-user delete-modal-description'>
          Do you really want to delete User
        </div>
      </Modal>
    </div>
  )
}

export default Users
