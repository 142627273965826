import React, { Fragment } from 'react'

import { ReactComponent as AddIcon } from '../assets/add-icon.svg'

const ListContent = ({
  data,
  title,
  columnTitles,
  createBtn,
  renderFilter,
  renderItemValue,
}) => {
  return (
    <div className='table-list'>
      <header className='table-list_header table-list-header'>
        <div className='table-list-header_title'>{title}</div>
        {renderFilter && renderFilter()}
        {createBtn && (
          <span
            className='table-list-header_add-btn add-btn btn'
            onClick={() => {
              createBtn()
            }}
          >
            <AddIcon />
          </span>
        )}
      </header>
      <div className='table-list_body-wrapper'>
        <ul className='table-list_body-inner'>
          <li className='table-list_item'>
            {columnTitles &&
              columnTitles.map((title, i) =>
                title === 'id' ? (
                  <div
                    key={i}
                    className='table-list_item-title table-list_item-title--id'
                  >
                    {title}
                  </div>
                ) : (
                  <div key={i} className='table-list_item-title'>
                    {title}
                  </div>
                ),
              )}
          </li>
          <li className='table-list_item table-list_row-wrapper'>
            {data.map(item => {
              return <Fragment key={item.id}>{renderItemValue(item)}</Fragment>
            })}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ListContent
