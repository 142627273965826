const UserActionTypes = {
  SET_USERS: 'SET_USERS',
  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',
  DELETE_USER: 'DELETE_USER',
  GET_COUNT: 'GET_COUNT',
  SET_ERROR: 'SET_ERROR',
  USER_SET_ENABLED: 'USER_SET_ENABLED',
}

export const UserLoadingTypes = {
  GET: 'GET',
  GET_ONE: 'GET_ONE',
  GET_COUNT: 'GET_COUNT',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  SET_ENABLED: 'SET_ENABLED',
}

export default UserActionTypes
