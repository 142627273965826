import CustomerActionTypes, { CustomerLoadingTypes } from './types'
import CustomerService from '../../../services/customer'
import { LoadingActionCreators } from '../loading/action-creators'
import { CUSTOMERS_DEVICES } from '../../../utils/consts'

const getTotalCount = async deviceType => {
  if (deviceType?.length) {
    const totalCounts = {}
    for (let value of deviceType) {
      try {
        const { data: count } =
          await CustomerService.getCustomerCountByAuthUser({
            deviceType: +value,
          })
        totalCounts[CUSTOMERS_DEVICES[value]] = count
      } catch (error) {
        return error
      }
    }

    return totalCounts
  }
  const { data: count } = await CustomerService.getCustomerCountByAuthUser({})
  return count
}

export const CustomerActionCreators = {
  setCustomers: customers => ({
    type: CustomerActionTypes.SET_CUSTOMERS,
    payload: customers,
  }),

  setError: payload => ({
    type: CustomerActionTypes.SET_ERROR,
    payload,
  }),

  _getCustomerCount: count => ({
    type: CustomerActionTypes.GET_CUSTOMER_COUNT,
    payload: count,
  }),

  _getCustomerTotalCounts: totalCounts => ({
    type: CustomerActionTypes.GET_CUSTOMER_TOTAL_COUNTS,
    payload: totalCounts,
  }),

  getCustomerCount: filter => async dispatch => {
    try {
      dispatch(
        LoadingActionCreators.loadingStart(CustomerLoadingTypes.GET_COUNT),
      )

      const { data: count } = await CustomerService.getCustomerCountByAuthUser(
        filter,
      )

      dispatch(CustomerActionCreators._getCustomerCount(count))
      dispatch(CustomerActionCreators.setError(''))
    } catch (e) {
      dispatch(CustomerActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(CustomerLoadingTypes.GET_COUNT))
    }
  },

  getCustomerTotalCounts: filter => async dispatch => {
    try {
      dispatch(
        LoadingActionCreators.loadingStart(
          CustomerLoadingTypes.GET_TOTAL_COUNTS,
        ),
      )

      const totalCount = await getTotalCount()
      const totalCountsDevices = await getTotalCount(
        Object.keys(CUSTOMERS_DEVICES),
      )

      dispatch(
        CustomerActionCreators._getCustomerTotalCounts({
          totalCount,
          ...totalCountsDevices,
        }),
      )
      dispatch(CustomerActionCreators.setError(''))
    } catch (e) {
      dispatch(CustomerActionCreators.setError('Error'))
    } finally {
      dispatch(
        LoadingActionCreators.loadingEnd(CustomerLoadingTypes.GET_TOTAL_COUNTS),
      )
    }
  },

  getCustomerListByAuthUser:
    (
      { passId, limit, offset } = {
        passId: '',
        limit: '',
        offset: '',
      },
    ) =>
    async (dispatch, getState) => {
      const {
        auth: { authUser },
      } = getState()

      try {
        dispatch(LoadingActionCreators.loadingStart(CustomerLoadingTypes.GET))

        if (authUser?.companyId) {
          const { data: customers } =
            await CustomerService.getCustomerListByAuthUser({
              filter: { passId },
              limit,
              offset,
            })

          dispatch(CustomerActionCreators.setCustomers(customers))
          dispatch(CustomerActionCreators.setError(''))
        }
      } catch (e) {
        dispatch(CustomerActionCreators.setError('Error'))
      } finally {
        dispatch(LoadingActionCreators.loadingEnd(CustomerLoadingTypes.GET))
      }
    },
}
