import { $private } from '../http'

export default class CustomerService {
  static async getCustomerListByAuthUser({ filter, limit, offset }) {
    return $private.post(`/Customer/list?limit=${limit}&offset=${offset}`, {
      ...filter,
    })
  }
  static async getCustomerCountByAuthUser(filter) {
    return $private.post(`/Customer/count`, { ...filter })
  }
}
