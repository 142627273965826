import { $private } from '../http'

export default class UserService {
  static async createUser(data) {
    return $private.post('/User', data)
  }

  static async updateUserById(data) {
    return $private.put(`/User`, data)
  }

  static async deleteUserById(id) {
    return $private.delete(`/User/${id}`)
  }

  static async getUserById(id) {
    return $private.get(`/User/${id}`)
  }

  //TODO: create one method to get a list of different users
  static async getUsersList(body) {
    return $private.post('/User/list', body)
  }

  static async getUserSelf() {
    return $private.get('/User/self')
  }

  static async getUsersCount(filter) {
    return $private.post(`/User/count`, filter)
  }

  static async userSetEnabledById(data) {
    return $private.post(`/User/setEnabled`, data)
  }
}
