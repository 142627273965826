const AuthActionTypes = {
  SET_AUTH: 'SET_AUTH',
  SET_ERROR: 'SET_ERROR',
  SET_USER: 'SET_USER',
}

export const AuthLoadingTypes = {
  SIGN_IN: 'SIGN_IN',
  LOGOUT: 'LOGOUT',
  CHECK_AUTH: 'CHECK_AUTH',
}

export default AuthActionTypes
