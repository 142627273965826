import React, { useCallback, useEffect, useState } from 'react'

const Pagination = ({ totalCount, perPage, reload }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [pageList, setPageList] = useState([])

  const recalculate = useCallback(() => {
    let pages = Math.ceil(totalCount / perPage)
    let pageList = []

    pageList = [...Array(pages).keys()].map(p => {
      if (p < 4 || p > pages - 5) {
        return p
      }
      if (p - currentPage < 4 && p - currentPage > -4) {
        return p
      }
      return '...'
    })

    pageList = pageList.filter((p, i) => i === 0 || pageList[i - 1] !== '...')
    setPageList(pageList)
  }, [currentPage, perPage, totalCount])

  useEffect(() => {
    recalculate()
  }, [recalculate])

  return (
    <div className='page-block'>
      {pageList.map((page, i) => {
        if (page === '...') {
          return (
            <div className='page-skip' key={`...${page}_${i}`}>
              ...
            </div>
          )
        }
        return (
          <div
            key={page}
            className={
              'page-button' + (page === currentPage ? ' page-current' : '')
            }
            onClick={() => {
              setCurrentPage(page)
              reload(page)
            }}
          >
            {page + 1}
          </div>
        )
      })}
    </div>
  )
}

export default Pagination
