import AuthActionTypes from './types'

const initialState = {
  isAuth: false,
  error: '',
  authUser: {},
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.SET_AUTH: {
      return { ...state, isAuth: action.payload }
    }
    case AuthActionTypes.SET_USER: {
      return { ...state, authUser: action.payload }
    }
    case AuthActionTypes.SET_ERROR: {
      return { ...state, error: action.payload }
    }
    default:
      return state
  }
}

export default authReducer
