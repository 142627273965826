//TODO: fix naming
//TODO:FIX function naming

import PassActionTypes, { PassLoadingTypes } from './types'
import PassService from '../../../services/pass'
import { LoadingActionCreators } from '../loading/action-creators'

export const PassActionCreators = {
  setPasses: pass => ({
    type: PassActionTypes.SET_PASSES,
    payload: pass,
  }),

  _getPassCount: count => ({
    type: PassActionTypes.GET_PASS_COUNT,
    payload: count,
  }),

  _createPass: pass => ({
    type: PassActionTypes.CREATE_PASS,
    payload: pass,
  }),

  _updatePass: pass => ({
    type: PassActionTypes.UPDATE_PASS,
    payload: pass,
  }),

  _deletePass: pass => ({
    type: PassActionTypes.DELETE_PASS,
    payload: pass,
  }),

  setError: payload => ({
    type: PassActionTypes.SET_ERROR,
    payload,
  }),

  _togglePassEnabled: payload => ({
    type: PassActionTypes.TOGGLE_ENABLED,
    payload,
  }),

  getPassCount: filter => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(PassLoadingTypes.GET_COUNT))

      const { data: count } = await PassService.getPassCount(filter)

      dispatch(PassActionCreators._getPassCount(count))
      dispatch(PassActionCreators.setError(''))
    } catch (e) {
      dispatch(PassActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(PassLoadingTypes.GET_COUNT))
    }
  },

  createPass: data => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(PassLoadingTypes.CREATE))

      const { data: pass } = await PassService.createPass(data)

      dispatch(LoadingActionCreators.loadingEnd(PassLoadingTypes.CREATE))

      dispatch(PassActionCreators._createPass(pass))
      dispatch(PassActionCreators.setError(''))
    } catch (e) {
      dispatch(PassActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(PassLoadingTypes.CREATE))
    }
  },

  updatePass: data => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(PassLoadingTypes.UPDATE))

      const { data: pass } = await PassService.updatePassById(data)

      dispatch(PassActionCreators._updatePass(pass))
      dispatch(PassActionCreators.setError(''))
    } catch (e) {
      dispatch(PassActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(PassLoadingTypes.UPDATE))
    }
  },

  togglePassEnabled: pass => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(PassLoadingTypes.ENABLED))

      const { data: updatedPass } = await PassService.togglePassEnabledById(
        pass.id,
      )

      dispatch(PassActionCreators._togglePassEnabled(updatedPass))
      dispatch(PassActionCreators.setError(''))
    } catch (e) {
      dispatch(PassActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(PassLoadingTypes.ENABLED))
    }
  },

  deletePass: id => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(PassLoadingTypes.DELETE))

      await PassService.deletePassById(id)

      dispatch(PassActionCreators._deletePass(id))
      dispatch(PassActionCreators.setError(''))
    } catch (e) {
      dispatch(PassActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(PassLoadingTypes.DELETE))
    }
  },

  getPassListByCompanyId:
    ({ limit, offset } = { limit: '', offset: '' }) =>
    async (dispatch, getState) => {
      const {
        auth: { authUser },
      } = getState()

      try {
        if (authUser?.companyId) {
          dispatch(LoadingActionCreators.loadingStart(PassLoadingTypes.GET))

          const { data: pass } = await PassService.getPassListByCompanyId({
            filter: { companyId: authUser?.companyId },
            limit,
            offset,
          })

          dispatch(PassActionCreators.setPasses(pass))
          dispatch(PassActionCreators.setError(''))
        }
      } catch (e) {
        dispatch(PassActionCreators.setError('Error'))
      } finally {
        dispatch(LoadingActionCreators.loadingEnd(PassLoadingTypes.GET))
      }
    },
}
