import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import {
  Company,
  Customers,
  Notifications,
  Passes,
  PassEditor,
  SuperUsers,
  CompaniesUsers,
} from '../components'
import AdminLayout from '../layouts/AdminLayout'

const AdminPage = () => {
  const {
    auth: { authUser },
  } = useSelector(({ auth }) => {
    return { auth }
  })

  //TODO: refactoring, changing the user role check for routing

  let routes = []

  if (authUser.role === 0) {
    routes = [
      { path: '/admin/companies', Component: CompaniesUsers },
      { path: '/admin/superusers', Component: SuperUsers },
    ]
  } else {
    routes = [
      { path: '/admin/company', Component: Company },
      { path: '/admin/passes', Component: Passes },
      { path: '/admin/passes/editor/:id', Component: PassEditor },
      { path: '/admin/messages', Component: Notifications },
      { path: '/admin/customers', Component: Customers },
    ]
  }

  return (
    <AdminLayout>
      <Switch>
        {routes.map(({ path, Component }) => (
          <Route key={path} path={path} component={Component} exact />
        ))}
        {authUser.role === 0 ? (
          <Redirect to={'/admin/companies'} />
        ) : (
          <Redirect to={'/admin/company'} />
        )}
      </Switch>
    </AdminLayout>
  )
}

export default AdminPage
