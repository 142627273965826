export const addZero = num => {
  if (num >= 0 && num <= 9) {
    return '0' + num
  } else {
    return num
  }
}

export const findIdxInArray = (array, id) => {
  return array.findIndex(item => item.id === id)
}

export const createNewItemInArray = (array, newItem, top = 'false') => {
  if (!top) return [...array, newItem]
  return [newItem, ...array]
}

export const updateItemInArray = (array, updatedItem, oldItemIndex) => {
  return [
    ...array.slice(0, oldItemIndex),
    updatedItem,
    ...array.slice(oldItemIndex + 1),
  ]
}

export const deleteItemInArray = (array, deletedItemIndex) => {
  return [
    ...array.slice(0, deletedItemIndex),
    ...array.slice(deletedItemIndex + 1),
  ]
}
