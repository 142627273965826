import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ListContent, Notifications, Pagination, Modal } from '.'
import { useActions } from '../hooks/useActions'
import { ReactComponent as DisabledIcon } from '../assets/disabled-icon.svg'
import { ReactComponent as ActiveIconBtn } from '../assets/active-icon.svg'
import { ReactComponent as EditIcon } from '../assets/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../assets/delete-icon.svg'
import {
  TABLE_LIST_ITEM_VALUE,
  TABLE_LIST_ITEM_VALUE__ID,
} from '../utils/consts'

const Passes = () => {
  const {
    pass: { passes, count: totalCount },
    auth: { authUser },
  } = useSelector(({ pass, auth }) => {
    return { pass, auth }
  })

  const [selectedPass, setSelectedPass] = useState('')

  const [confirmDeletePassModalActive, setConfirmDeletePassModalActive] =
    useState(false)

  const [deleteCandidatePass, setDeleteCandidatePass] = useState({})

  const {
    deletePass,
    getPassListByCompanyId,
    getPassCount,
    togglePassEnabled,
  } = useActions()

  const history = useHistory()

  const getPassList = useCallback(
    async page => {
      if (!page) page = 0

      await getPassListByCompanyId({
        limit: 10,
        offset: page * 10,
      })

      await getPassCount({
        companyId: authUser.companyId,
      })
    },
    [getPassListByCompanyId, getPassCount, authUser.companyId],
  )

  const renderItemValue = item => {
    return (
      <div
        className={
          selectedPass === item.id
            ? 'table-list_row table-list_row--selected'
            : 'table-list_row'
        }
        onClick={() => {
          if (selectedPass === item.id) {
            setSelectedPass('')
            return
          }
          setSelectedPass(item.id)
        }}
      >
        <div className={TABLE_LIST_ITEM_VALUE__ID}>{item.id}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>{item.title}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>{item.description}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>{item.organizationName}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>
          <button
            className='table-list_item-btn'
            onClick={async e => {
              e.stopPropagation()
              togglePassEnabled(item)
            }}
          >
            {item.enabled ? <DisabledIcon /> : <ActiveIconBtn />}
          </button>
          <button
            className='table-list_item-btn'
            onClick={e => {
              e.stopPropagation()
              history.push(`/admin/passes/editor/${item.id}`)
            }}
          >
            <EditIcon />
          </button>
          <button
            className='table-list_item-btn'
            onClick={e => {
              e.stopPropagation()
              setDeleteCandidatePass(item)
              setConfirmDeletePassModalActive(true)
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    )
  }
  const reload = page => getPassList(page)

  useEffect(() => {
    setSelectedPass(passes?.[0]?.id)
  }, [passes])

  useEffect(() => {
    ;(async () => {
      await getPassList()
    })()
  }, [getPassList])

  return (
    <>
      <div className='passes'>
        <ListContent
          title={'Passes'}
          columnTitles={[
            'id',
            'title',
            'description',
            'organization name',
            'action',
          ]}
          renderItemValue={renderItemValue}
          data={passes}
          createBtn={
            authUser?.role !== 3
              ? () => {
                  history.push('/admin/passes/editor/new')
                }
              : false
          }
        />
        <Pagination
          perPage={10}
          totalCount={totalCount}
          reload={reload}
        ></Pagination>
        <Modal
          confirm
          active={confirmDeletePassModalActive}
          setActive={setConfirmDeletePassModalActive}
          title={`Delete Pass`}
          footer={
            <div className='passes_delete-pass-footer '>
              <div className='modal-footer-buttons'>
                <button
                  className='modal-footer-buttons_btn admin-btn'
                  onClick={() => {
                    deletePass(deleteCandidatePass.id)
                    setConfirmDeletePassModalActive(false)
                  }}
                >
                  Ok
                </button>
                <button
                  className='modal-footer-buttons_btn admin-btn'
                  onClick={() => {
                    setConfirmDeletePassModalActive(false)
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          }
        >
          <div className='passes_delete-pass delete-modal-description'>
            Do you really want to delete Pass
          </div>
        </Modal>
      </div>

      {selectedPass && (
        <Notifications notFilter passSelectedPass={selectedPass} />
      )}
    </>
  )
}

export default Passes
