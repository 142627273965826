import auth from './auth'
import user from './user'
import company from './company'
import pass from './pass'
import customer from './customer'
import notification from './notification'
import loading from './loading'

const reducers = { auth, user, company, pass, customer, notification, loading }

export default reducers
