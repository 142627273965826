import {
  createNewItemInArray,
  deleteItemInArray,
  findIdxInArray,
  updateItemInArray,
} from '../../../utils/functions'
import NotificationActionTypes from './types'

const initialState = {
  notifications: [],
  count: 0,
  error: '',
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case NotificationActionTypes.SET_NOTIFICATIONS: {
      return { ...state, notifications: action.payload }
    }
    case NotificationActionTypes.CREATE_NOTIFICATION: {
      return {
        ...state,
        notifications: createNewItemInArray(
          state.notifications,
          action.payload,
          true,
        ),
      }
    }
    case NotificationActionTypes.UPDATE_NOTIFICATION: {
      const idx = findIdxInArray(state.notifications, action.payload.id)
      return {
        ...state,
        notifications: updateItemInArray(
          state.notifications,
          action.payload,
          idx,
        ),
      }
    }
    case NotificationActionTypes.UPDATE_ORDER: {
      return {
        ...state,
        notifications: action.payload,
      }
    }
    case NotificationActionTypes.DELETE_NOTIFICATION: {
      const idx = findIdxInArray(state.notifications, action.payload)
      return {
        ...state,
        notifications: deleteItemInArray(state.notifications, idx),
      }
    }
    case NotificationActionTypes.GET_COUNT: {
      return {
        ...state,
        count: action.payload,
      }
    }
    case NotificationActionTypes.SET_ERROR: {
      return { ...state, error: action.payload }
    }
    default:
      return state
  }
}

export default userReducer
