import AuthActionTypes, { AuthLoadingTypes } from './types'
import AuthService from '../../../services/auth'
import axios from 'axios'
import UserService from '../../../services/user'
import { LoadingActionCreators } from '../loading/action-creators'

export const AuthActionCreators = {
  setUser: authUser => ({
    type: AuthActionTypes.SET_USER,
    payload: authUser,
  }),

  setIsAuth: auth => ({
    type: AuthActionTypes.SET_AUTH,
    payload: auth,
  }),

  setError: payload => ({
    type: AuthActionTypes.SET_ERROR,
    payload,
  }),

  signIn: (login, password) => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(AuthLoadingTypes.SIGN_IN))

      const {
        data: { auth, refresh },
      } = await AuthService.signIn(login, password)

      if (auth && refresh) {
        localStorage.setItem('token', auth)
        localStorage.setItem('refreshToken', refresh)
        dispatch(AuthActionCreators.setIsAuth(true))

        const { data: authUser } = await UserService.getUserSelf()
        dispatch(AuthActionCreators.setUser(authUser))

        dispatch(AuthActionCreators.setError(''))
      } else {
        dispatch(AuthActionCreators.setError('Incorrect username or password'))
      }
    } catch (e) {
      dispatch(
        AuthActionCreators.setError('An error occurred while logging in'),
      )
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(AuthLoadingTypes.SIGN_IN))
    }
  },

  signOut: () => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(AuthLoadingTypes.LOGOUT))

      await AuthService.signOut()

      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      dispatch(AuthActionCreators.setIsAuth(false))
      dispatch(AuthActionCreators.setUser({}))
      dispatch(AuthActionCreators.setError(''))
    } catch (e) {
      dispatch(AuthActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(AuthLoadingTypes.LOGOUT))
    }
  },

  checkAuth: () => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(AuthLoadingTypes.CHECK_AUTH))

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL_AUTH}/refresh`,
        {},
        {
          headers: {
            Authorization: `${localStorage.getItem('refreshToken')}`,
          },
        },
      )

      if (data.auth && data.refresh) {
        localStorage.setItem('token', data.auth)
        localStorage.setItem('refreshToken', data.refresh)

        const { data: authUser } = await UserService.getUserSelf()
        dispatch(AuthActionCreators.setUser(authUser))

        dispatch(AuthActionCreators.setIsAuth(true))
        dispatch(AuthActionCreators.setError(''))
      } else {
        dispatch(AuthActionCreators.setError('Error'))
      }
    } catch (e) {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      dispatch(AuthActionCreators.setIsAuth(false))
      dispatch(AuthActionCreators.setUser({}))
      dispatch(AuthActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(AuthLoadingTypes.CHECK_AUTH))
    }
  },
}
