import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppRouter, Loader } from './components'
import { AuthLoadingTypes } from './redux/reducers/auth/types'

import './styles/index.scss'

const App = () => {
  const { subjects } = useSelector(({ loading }) => {
    return loading
  })

  return (
    <Router>
      <>
        {subjects.size > 0 && (
          <div
            className={
              subjects.has(AuthLoadingTypes.CHECK_AUTH)
                ? 'loading-wrapper loading-wrapper--auth'
                : 'loading-wrapper'
            }
          >
            <Loader />
          </div>
        )}
        <AppRouter />
      </>
    </Router>
  )
}

export default App
