import { AdminPage, AuthPage } from './pages'
import { ADMIN_PATH_PARAM, AUTH_ROUTE } from './utils/consts'

export const privateRoutes = [{ path: ADMIN_PATH_PARAM, Component: AdminPage }]

export const publicRoutes = [
  {
    path: AUTH_ROUTE,
    Component: AuthPage,
  },
]
