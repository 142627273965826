import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { ReactComponent as BurgerMenuIcon } from '../assets/burger-menu.svg'
import { USER_ROLES } from '../utils/consts'

function useOutsideAlerter(ref, setUserMenu) {
  useEffect(() => {
    function handleClickOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setUserMenu(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, setUserMenu])
}

const Header = ({ setLeftColumnShowToggle }) => {
  const {
    authUser: { role },
  } = useSelector(({ auth }) => auth)

  const { signOut } = useActions()

  const [userMenuShowToggle, setUserMenuShowToggle] = useState(false)

  const userMenuRef = useRef(null)

  useOutsideAlerter(userMenuRef, setUserMenuShowToggle)

  return (
    <header className='admin-header'>
      <div className='admin-header_left-part'>
        <div
          className='left-menu-show-toggle-btn'
          onClick={() => {
            setLeftColumnShowToggle(oldState => !oldState)
          }}
        >
          <BurgerMenuIcon />
        </div>
      </div>
      <div className='admin-header_right-part'>
        <div className='user-info' ref={userMenuRef}>
          <button
            className='user-login'
            onClick={() => setUserMenuShowToggle(oldState => !oldState)}
          >
            {USER_ROLES[role]}
          </button>
          {userMenuShowToggle && (
            <ul className='user-menu'>
              <li
                onClick={() => {
                  signOut()
                }}
              >
                Logout
              </li>
            </ul>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
