import UserActionTypes, { UserLoadingTypes } from './types'
import UserService from '../../../services/user'
import { LoadingActionCreators } from '../loading/action-creators'

export const UserActionCreators = {
  setUsers: users => ({
    type: UserActionTypes.SET_USERS,
    payload: users,
  }),

  setError: payload => ({
    type: UserActionTypes.SET_ERROR,
    payload,
  }),

  _userSetEnabled: payload => ({
    type: UserActionTypes.USER_SET_ENABLED,
    payload,
  }),

  _createUser: user => ({
    type: UserActionTypes.CREATE_USER,
    payload: user,
  }),

  _updateUser: user => ({
    type: UserActionTypes.UPDATE_USER,
    payload: user,
  }),

  _deleteUser: user => ({
    type: UserActionTypes.DELETE_USER,
    payload: user,
  }),

  _getUsersCount: count => ({
    type: UserActionTypes.GET_COUNT,
    payload: count,
  }),

  userSetEnabled: data => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(UserLoadingTypes.SET_ENABLED))

      await UserService.userSetEnabledById(data)

      dispatch(LoadingActionCreators.loadingEnd(UserLoadingTypes.SET_ENABLED))

      dispatch(UserActionCreators._userSetEnabled(data))
      dispatch(UserActionCreators.setError(''))
    } catch (e) {
      dispatch(UserActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(UserLoadingTypes.CREATE))
    }
  },

  createUser: data => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(UserLoadingTypes.CREATE))

      const { data: user } = await UserService.createUser(data)

      dispatch(LoadingActionCreators.loadingEnd(UserLoadingTypes.CREATE))

      dispatch(UserActionCreators._createUser(user))
      dispatch(UserActionCreators.setError(''))
    } catch (e) {
      dispatch(UserActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(UserLoadingTypes.CREATE))
    }
  },

  updateUser: data => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(UserLoadingTypes.UPDATE))

      const { data: user } = await UserService.updateUserById(data)

      dispatch(UserActionCreators._updateUser(user))
      dispatch(UserActionCreators.setError(''))
    } catch (e) {
      dispatch(UserActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(UserLoadingTypes.UPDATE))
    }
  },

  deleteUser: id => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(UserLoadingTypes.DELETE))

      await UserService.deleteUserById(id)

      dispatch(UserActionCreators._deleteUser(id))

      dispatch(UserActionCreators.setError(''))
    } catch (e) {
      dispatch(UserActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(UserLoadingTypes.DELETE))
    }
  },

  getUsersCount: filter => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(UserLoadingTypes.GET_COUNT))

      const { data: count } = await UserService.getUsersCount(filter)

      dispatch(UserActionCreators._getUsersCount(count))
      dispatch(UserActionCreators.setError(''))
    } catch (e) {
      dispatch(UserActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(UserLoadingTypes.GET_COUNT))
    }
  },

  //TODO: fix and refactoring
  getUsersList: body => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(UserLoadingTypes.GET))

      const { data: users } = await UserService.getUsersList(body)

      dispatch(UserActionCreators.setUsers(users))
      dispatch(UserActionCreators.setError(''))
    } catch (e) {
      dispatch(UserActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(UserLoadingTypes.GET))
    }
  },
}
