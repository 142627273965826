//TODO: fix naming

import NotificationActionTypes, { NotificationsLoadingTypes } from './types'
import NotificationService from '../../../services/notification'
import { LoadingActionCreators } from '../loading/action-creators'

export const NotificationActionCreators = {
  setNotifications: notifications => ({
    type: NotificationActionTypes.SET_NOTIFICATIONS,
    payload: notifications,
  }),
  _updateOrder: items => ({
    type: NotificationActionTypes.UPDATE_ORDER,
    payload: items,
  }),

  _createNotification: notification => ({
    type: NotificationActionTypes.CREATE_NOTIFICATION,
    payload: notification,
  }),

  _updateNotification: notification => ({
    type: NotificationActionTypes.UPDATE_NOTIFICATION,
    payload: notification,
  }),

  _deleteNotification: notification => ({
    type: NotificationActionTypes.DELETE_NOTIFICATION,
    payload: notification,
  }),
  _cancelSchedule: notification => ({
    type: NotificationActionTypes.DELETE_SCHEDULE,
    payload: notification,
  }),


  _getNotificationsCount: count => ({
    type: NotificationActionTypes.GET_COUNT,
    payload: count,
  }),

  setError: payload => ({
    type: NotificationActionTypes.SET_ERROR,
    payload,
  }),

  createNotification: data => async dispatch => {
    try {
      dispatch(
        LoadingActionCreators.loadingStart(NotificationsLoadingTypes.CREATE),
      )

      const { data: notification } =
        await NotificationService.createNotification(data)

      dispatch(
        LoadingActionCreators.loadingEnd(NotificationsLoadingTypes.CREATE),
      )

      dispatch(NotificationActionCreators._createNotification(notification))
      dispatch(NotificationActionCreators.setError(''))
    } catch (e) {
      dispatch(NotificationActionCreators.setError('Error'))
    } finally {
      dispatch(
        LoadingActionCreators.loadingEnd(NotificationsLoadingTypes.CREATE),
      )
    }
  },

  notificationsDragAndDrop: (items, obj) => async dispatch => {
    try {
      dispatch(NotificationActionCreators._updateOrder(items))

      await NotificationService.updateOrder(obj)

      dispatch(NotificationActionCreators.setError(''))
    } catch (e) {
      dispatch(NotificationActionCreators.setError('Error'))
    } finally {
    }
  },

  updateNotification: data => async dispatch => {
    try {
      dispatch(
        LoadingActionCreators.loadingStart(NotificationsLoadingTypes.UPDATE),
      )

      const { data: notification } =
        await NotificationService.updateNotificationById(data)

      dispatch(NotificationActionCreators._updateNotification(notification))
      dispatch(NotificationActionCreators.setError(''))
    } catch (e) {
      dispatch(NotificationActionCreators.setError('Error'))
    } finally {
      dispatch(
        LoadingActionCreators.loadingEnd(NotificationsLoadingTypes.UPDATE),
      )
    }
  },

  deleteNotification: id => async dispatch => {
    try {
      dispatch(
        LoadingActionCreators.loadingStart(NotificationsLoadingTypes.DELETE),
      )

      await NotificationService.deleteNotificationById(id)

      dispatch(NotificationActionCreators._deleteNotification(id))
      dispatch(NotificationActionCreators.setError(''))
    } catch (e) {
      dispatch(NotificationActionCreators.setError('Error'))
    } finally {
      dispatch(
        LoadingActionCreators.loadingEnd(NotificationsLoadingTypes.DELETE),
      )
    }
  },

  cancelSchedule: id => async dispatch => {
    try {
      dispatch(
          LoadingActionCreators.loadingStart(NotificationsLoadingTypes.UPDATE),
      )

      await NotificationService.cancelScheduleById(id)

      dispatch(NotificationActionCreators._cancelSchedule(id))
      dispatch(NotificationActionCreators.setError(''))
    } catch (e) {
      dispatch(NotificationActionCreators.setError('Error'))
    } finally {
      dispatch(
          LoadingActionCreators.loadingEnd(NotificationsLoadingTypes.UPDATE),
      )
    }
  },

  getNotificationsCount: filter => async dispatch => {
    try {
      dispatch(
        LoadingActionCreators.loadingStart(NotificationsLoadingTypes.GET_COUNT),
      )

      const { data: count } = await NotificationService.getNotificationsCount(
        filter,
      )

      dispatch(NotificationActionCreators._getNotificationsCount(count))
      dispatch(NotificationActionCreators.setError(''))
    } catch (e) {
      dispatch(NotificationActionCreators.setError('Error'))
    } finally {
      dispatch(
        LoadingActionCreators.loadingEnd(NotificationsLoadingTypes.GET_COUNT),
      )
    }
  },

  getNotificationListByPassId:
    ({ passId, limit, offset } = { passId: '', limit: '', offset: '' }) =>
    async dispatch => {
      try {
        dispatch(
          LoadingActionCreators.loadingStart(NotificationsLoadingTypes.GET),
        )

        const { data: notifications } =
          await NotificationService.getNotificationListByPassId({
            filter: { passId },
            limit,
            offset,
          })

        dispatch(NotificationActionCreators.setNotifications(notifications))
        dispatch(NotificationActionCreators.setError(''))
      } catch (e) {
        dispatch(NotificationActionCreators.setError('Error'))
      } finally {
        dispatch(
          LoadingActionCreators.loadingEnd(NotificationsLoadingTypes.GET),
        )
      }
    },
}
