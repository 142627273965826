import axios from 'axios'

export const $public = axios.create({
  baseURL: process.env.REACT_APP_API_URL_AUTH,
})

export const $private = axios.create({
  baseURL: process.env.REACT_APP_API_URL_PRIVATE,
})

$private.interceptors.request.use(config => {
  if (localStorage.getItem('token')) {
    config.headers.Authorization = localStorage.getItem('token')
  }
  return config
})

$private.interceptors.response.use(
  config => {
    return config
  },
  async error => {
    const originalRequest = error.config
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true
      try {
        const {
          data: { auth, refresh },
        } = await axios.post(
          `${process.env.REACT_APP_API_URL_AUTH}/refresh`,
          {},
          {
            headers: {
              Authorization: `${localStorage.getItem('refreshToken')}`,
            },
          },
        )
        localStorage.setItem('token', auth)
        localStorage.setItem('refreshToken', refresh)
        return $private.request(originalRequest)
      } catch (e) {
        console.log('NOT AUTHORIZED')
      }
    }
    throw error
  },
)
