import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

const LeftMenu = () => {
  const {
    auth: { authUser },
  } = useSelector(({ auth }) => {
    return { auth }
  })

  let leftMenuItems = []

  if (authUser.role === 0) {
    leftMenuItems = [
      { id: 2, to: '/admin/companies', name: 'Companies-Users' },
      { id: 3, to: '/admin/superusers', name: 'Super-Users' },
    ]
  } else {
    leftMenuItems = [
      { id: 1, to: '/admin/company', name: 'Company' },
      { id: 4, to: '/admin/passes', name: 'Passes' },
      { id: 5, to: '/admin/messages', name: 'Messages' },
      { id: 6, to: '/admin/customers', name: 'Customers' },
    ]
  }

  return (
    <nav className='left-menu-wrapper'>
      <ul className='left-menu-inner'>
        {leftMenuItems.map(({ id, to, name }) => (
          <li key={id} className='left-menu-inner_item'>
            <NavLink
              className='left-menu-inner_link'
              activeClassName='left-menu-inner_link--active'
              to={to}
            >
              {name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default LeftMenu
