import { $private } from '../http'

export default class PassService {
  static async createPass(data) {
    return $private.post('/Pass', data)
  }
  static async updatePassById(data) {
    return $private.put('/Pass', data)
  }
  static async getPassListByCompanyId({ filter, limit, offset }) {
    return $private.post(`/Pass/list?limit=${limit}&offset=${offset}`, {
      ...filter,
      state: 1,
    })
  }
  static async getPassById(id) {
    return $private.get(`/Pass/${id}`)
  }
  static async deletePassById(id) {
    return $private.delete(`/Pass/${id}`)
  }
  static async togglePassEnabledById(id) {
    return $private.put(`/Pass/Toggle/${id}`)
  }
  static async getPassCount(filter) {
    return $private.post(`/Pass/count`, filter)
  }
}
