const NotificationActionTypes = {
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
  DELETE_SCHEDULE: 'DELETE_SCHEDULE',
  GET_COUNT: 'GET_COUNT',
  SET_ERROR: 'SET_ERROR',
  UPDATE_ORDER: 'UPDATE_ORDER',
}

export const NotificationsLoadingTypes = {
  GET: 'GET',
  GET_ONE: 'GET_ONE',
  GET_COUNT: 'GET_COUNT',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  PUSH: 'PUSH',
}

export default NotificationActionTypes
