import {
  createNewItemInArray,
  deleteItemInArray,
  findIdxInArray,
  updateItemInArray,
} from '../../../utils/functions'
import CompanyActionTypes from './types'

const initialState = {
  error: '',
  company: {},
  companies: [],
}

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case CompanyActionTypes.SET_COMPANY: {
      return { ...state, company: action.payload }
    }
    case CompanyActionTypes.SET_COMPANIES: {
      return { ...state, companies: action.payload }
    }

    case CompanyActionTypes.CREATE_COMPANY: {
      return {
        ...state,
        companies: createNewItemInArray(state.companies, action.payload),
      }
    }
    case CompanyActionTypes.UPDATE_COMPANY: {
      const idx = findIdxInArray(state.companies, action.payload.id)
      return {
        ...state,
        companies: updateItemInArray(state.companies, action.payload, idx),
      }
    }

    case CompanyActionTypes.DELETE_COMPANY: {
      const idx = findIdxInArray(state.companies, action.payload)
      return {
        ...state,
        companies: deleteItemInArray(state.companies, idx),
      }
    }

    case CompanyActionTypes.SET_ERROR: {
      return { ...state, error: action.payload }
    }

    default:
      return state
  }
}

export default companyReducer
