const PassActionTypes = {
  SET_PASSES: 'SET_PASSES',
  CREATE_PASS: 'CREATE_PASS',
  UPDATE_PASS: 'UPDATE_PASS',
  DELETE_PASS: 'DELETE_PASS',
  SET_ERROR: 'SET_ERROR',
  TOGGLE_ENABLED: 'TOGGLE_ENABLED',
  GET_PASS_COUNT: 'GET_PASS_COUNT',
}

export const PassLoadingTypes = {
  GET: 'GET',
  GET_COUNT: 'GET_COUNT',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  GET_ONE: 'GET_ONE',
  ENABLED: 'ENABLED',
}

export default PassActionTypes
