import CustomerActionTypes from './types'

const initialState = {
  customers: [],
  count: 0,
  totalCounts: {},
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case CustomerActionTypes.SET_CUSTOMERS: {
      return { ...state, customers: action.payload }
    }
    case CustomerActionTypes.GET_CUSTOMER_COUNT: {
      return { ...state, count: action.payload }
    }
    case CustomerActionTypes.SET_ERROR: {
      return { ...state, error: action.payload }
    }
    case CustomerActionTypes.GET_CUSTOMER_TOTAL_COUNTS: {
      return { ...state, totalCounts: action.payload }
    }
    default:
      return state
  }
}

export default authReducer
