import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ListContent, Pagination } from '.'
import { useActions } from '../hooks/useActions'
import { addZero } from '../utils/functions'
import {
  CUSTOMERS_DEVICES,
  TABLE_LIST_ITEM_VALUE,
  TABLE_LIST_ITEM_VALUE__ID,
} from '../utils/consts'

const Customers = () => {
  const {
    pass: { passes },
    customer: { customers, count: totalCount, totalCounts },
  } = useSelector(({ customer, pass }) => {
    return { customer, pass }
  })

  const [passFilter, setPassFilter] = useState()

  const {
    getCustomerListByAuthUser,
    getCustomerCount,
    getCustomerTotalCounts,
    getPassListByCompanyId,
  } = useActions()

  const getCustomerList = useCallback(
    async page => {
      if (!page) page = 0

      await getCustomerListByAuthUser({
        passId: passFilter ? +passFilter : '',
        limit: 10,
        offset: page * 10,
      })

      await getCustomerCount({
        passId: passFilter ? +passFilter : '',
      })
    },
    [getCustomerListByAuthUser, getCustomerCount, passFilter],
  )

  const renderItemValue = item => {
    const date = new Date(item.created)
    return (
      <div className='table-list_row'>
        <div className={TABLE_LIST_ITEM_VALUE__ID}>{item.id}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>{item.ip}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>
          {CUSTOMERS_DEVICES[item.os]}
        </div>
        <div className={TABLE_LIST_ITEM_VALUE}>
          {addZero(date.getFullYear()) +
            '-' +
            addZero(date.getMonth() + 1) +
            '-' +
            addZero(date.getDate())}
        </div>
      </div>
    )
  }

  const renderFilter = () => {
    return (
      <div className='table-list_filter'>
        Pass{' '}
        <select
          value={passFilter}
          onChange={e => {
            setPassFilter(e.target.value)
          }}
        >
          <option value={''}>Not selected</option>
          {passes.map(({ id, title }) => (
            <option key={id} value={id}>
              {title}
            </option>
          ))}
        </select>
      </div>
    )
  }

  const reload = page => getCustomerList(page)

  useEffect(() => {
    ;(async () => {
      await getPassListByCompanyId()
      await getCustomerTotalCounts()
    })()
  }, [getPassListByCompanyId, getCustomerTotalCounts])

  useEffect(() => {
    getCustomerList()
  }, [getCustomerList])

  return (
    <div className='customers'>
      <ListContent
        title={
          <>
            Customers
            <div className='customer-totals'>
              {Object.keys(totalCounts).length !== 0 && (
                <>
                  <div>{`Total: ${totalCounts.totalCount}`}</div>
                  {Object.keys(totalCounts)
                    .slice(1)
                    .map((key, i) => {
                      return (
                        <div key={i}>{`Total ${key}: ${totalCounts[key]}`}</div>
                      )
                    })}
                </>
              )}
            </div>
          </>
        }
        columnTitles={['id', 'ip', 'os', 'created']}
        renderItemValue={renderItemValue}
        data={customers}
        renderFilter={renderFilter}
      />
      <Pagination
        perPage={10}
        totalCount={totalCount}
        reload={reload}
      ></Pagination>
    </div>
  )
}

export default Customers
