import { $private } from '../http'

export default class CompanyService {
  static async getCompanyList(body) {
    return $private.post(`/Company/list`, body)
  }

  static async createCompany(body) {
    return $private.post(`/Company`, body)
  }

  static async updateCompany(body) {
    return $private.put(`/Company`, body)
  }

  static async deleteCompany(id) {
    return $private.delete(`/Company/${id}`)
  }

  static async getCompanyById(id) {
    return $private.get(`/Company/${id}`)
  }
}
