import React, { useEffect } from 'react'

import { ReactComponent as CloseIcon } from '../assets/close-icon.svg'

const Modal = ({
  active = false,
  setActive,
  title,
  children,
  footer,
  confirm,
}) => {
  const onKeydown = ({ key }) => {
    switch (key) {
      case 'Escape':
        setActive(false)
        break
      default:
        break
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeydown)
    return () => document.removeEventListener('keydown', onKeydown)
  })

  if (!active) return null

  return (
    <div
      className={confirm ? 'modal modal--confirm' : 'modal'}
      onClick={() => setActive(false)}
    >
      <div className='modal-dialog' onClick={e => e.stopPropagation()}>
        <div className='modal-header'>
          {title && <h3 className='modal-title'>{title}</h3>}
          <span className='modal-close' onClick={() => setActive(false)}>
            <CloseIcon />
          </span>
        </div>
        <div className='modal-body'>
          <div className='modal-content'>{children}</div>
        </div>
        {footer && <div className='modal-footer'>{footer}</div>}
      </div>
    </div>
  )
}

export default Modal
