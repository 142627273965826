import { $public } from '../http'

export default class AuthService {
  static async signIn(login, password) {
    return $public.post('/signIn', { login, password })
  }

  static async signOut() {
    return $public.post('/signOut')
  }
}
