import {
  createNewItemInArray,
  deleteItemInArray,
  findIdxInArray,
  updateItemInArray,
} from '../../../utils/functions'
import PassActionTypes from './types'

const initialState = {
  passes: [],
  count: 0,
  selectedPass: '',
  error: '',
}

const passReducer = (state = initialState, action) => {
  switch (action.type) {
    case PassActionTypes.SET_PASSES: {
      return { ...state, passes: action.payload }
    }
    case PassActionTypes.CREATE_PASS: {
      return {
        ...state,
        passes: createNewItemInArray(state.passes, action.payload),
      }
    }
    case PassActionTypes.UPDATE_PASS: {
      const idx = findIdxInArray(state.passes, action.payload.id)
      return {
        ...state,
        passes: updateItemInArray(state.passes, action.payload, idx),
      }
    }
    case PassActionTypes.GET_PASS_COUNT: {
      return {
        ...state,
        count: action.payload,
      }
    }
    case PassActionTypes.TOGGLE_ENABLED: {
      const idx = findIdxInArray(state.passes, action.payload.id)
      return {
        ...state,
        passes: updateItemInArray(state.passes, action.payload, idx),
      }
    }
    case PassActionTypes.DELETE_PASS: {
      const idx = findIdxInArray(state.passes, action.payload)
      return {
        ...state,
        passes: deleteItemInArray(state.passes, idx),
      }
    }
    case PassActionTypes.SET_ERROR: {
      return { ...state, error: action.payload }
    }
    default:
      return state
  }
}

export default passReducer
