import UserActionTypes from './types'
import {
  createNewItemInArray,
  deleteItemInArray,
  findIdxInArray,
  updateItemInArray,
} from '../../../utils/functions'

const initialState = {
  users: [],
  count: '',
  error: '',
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.SET_USERS: {
      return { ...state, users: action.payload }
    }
    case UserActionTypes.CREATE_USER: {
      return {
        ...state,
        users: createNewItemInArray(state.users, action.payload),
      }
    }
    case UserActionTypes.USER_SET_ENABLED: {
      const idx = findIdxInArray(state.users, action.payload.id)
      return {
        ...state,
        users: updateItemInArray(state.users, action.payload, idx),
      }
    }
    case UserActionTypes.UPDATE_USER: {
      const idx = findIdxInArray(state.users, action.payload.id)
      return {
        ...state,
        users: updateItemInArray(state.users, action.payload, idx),
      }
    }
    case UserActionTypes.DELETE_USER: {
      const idx = findIdxInArray(state.users, action.payload)
      return {
        ...state,
        users: deleteItemInArray(state.users, idx),
      }
    }
    case UserActionTypes.GET_COUNT: {
      return {
        ...state,
        count: action.payload,
      }
    }
    case UserActionTypes.SET_ERROR: {
      return { ...state, error: action.payload }
    }
    default:
      return state
  }
}

export default userReducer
