import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import AdminUsers from './AdminUsers'

const Company = () => {
  const {
    company: { company },
    auth: { authUser },
  } = useSelector(({ auth, company }) => {
    return { company, auth }
  })

  const { getCompanyById } = useActions()

  useEffect(() => {
    ;(async () => {
      getCompanyById()
    })()
  }, [getCompanyById])

  return (
    <div className='company'>
      {company?.name && (
        <div className='company_name'>Name: {company.name}</div>
      )}
      {company?.description && (
        <div className='company_description'>
          Description: {company.description}
        </div>
      )}

      {authUser?.role === 1 && <AdminUsers />}
    </div>
  )
}

export default Company
