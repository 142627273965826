import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { USER_ROLES } from '../utils/consts'
import Users from './Users'

const AdminUsers = () => {
  const {
    auth: { authUser },
  } = useSelector(({ auth }) => {
    return { auth }
  })
  const { getUsersList, createUser, updateUser, deleteUser } = useActions()

  const userActions = useMemo(
    () => ({
      getData: () => {
        getUsersList({
          companyId: authUser?.companyId,
        })
      },
      createData: data => {
        createUser({
          companyId: authUser?.companyId,
          ...data,
        })
      },
      updateData: data => {
        updateUser({
          companyId: authUser?.companyId,
          ...data,
        })
      },
      deleteData: id => {
        deleteUser(id)
      },
    }),
    [getUsersList, createUser, updateUser, deleteUser, authUser?.companyId],
  )

  return (
    <div className='super-users'>
      <Users
        roles={Object.keys(USER_ROLES).filter(key => +key !== 0)}
        {...userActions}
      />
    </div>
  )
}

export default AdminUsers
