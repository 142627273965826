import React, { createRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import PassService from '../services/pass'

import { ReactComponent as DeleteIcon } from '../assets/close-icon.svg'
import { ReactComponent as AddIcon } from '../assets/Vector.svg'

import { ReactComponent as CloseIcon } from '../assets/close-icon.svg'
import { useActions } from '../hooks/useActions'
import { PassLoadingTypes } from '../redux/reducers/pass/types'
import { BARCODE_TYPE, PASS_STYLE } from '../utils/consts'

const PassEditor = () => {
  const { authUser } = useSelector(({ auth }) => {
    return auth
  })

  const [state, setState] = useState({
    title: '',
    description: '',
    identifier: '',
    logoText: '',
    backgroundColor: '#B0F8DE',
    labelColor: '#F6A4B8',
    foregroundColor: '#C4D2FC',
    organizationName: '',
    url: '',
    barcode: '',
    barcodeType: 0,
    gaTrackingId: '',
    style: 0,
    icons: {
      iosIcon: '',
      iosIcon2X: '',
      iosIcon3X: '',
    },
    iconsbackGround: {
      iosBackground: '',
      iosBackground2X: '',
      iosBackground3X: '',
    },
    iconsLogo: {
      iosLogo: '',
      iosLogo2X: '',
      iosLogo3X: '',
    },
    iconsStrip: {
      iosStrip: '',
      iosStrip2X: '',
      iosStrip3X: '',
    },
    iconsThumbnail: {
      iosThumbnail: '',
      iosThumbnail2X: '',
      iosThumbnail3X: '',
    },
    iconFooter: {
      iosFooter: '',
      iosFooter2X: '',
      iosFooter3X: '',
    },
    headerFields: [
      {
        value: '',
        label: '',
        attributedValue: '',
        row: 0,
      },
    ],
    primaryField: {
      value: '',
      label: '',
      attributedValue: '',
    },
    secondaryFields: [
      {
        value: '',
        label: '',
        attributedValue: '',
        row: 0,
      },
    ],
    auxiliaryFields: [
      {
        value: '',
        label: '',
        attributedValue: '',
        row: 0,
      },
    ],
    id: -1,
    enabled: false,
  })

  const { createPass, updatePass, loadingStart, loadingEnd } = useActions()

  const history = useHistory()

  const params = useParams()

  const { id: passId } = params

  useEffect(() => {
    ; (async () => {
      try {
        if (typeof +passId === 'number' && !isNaN(+passId)) {
          loadingStart(PassLoadingTypes.GET_ONE)

          const { data } = await PassService.getPassById(passId)

          setState({
            ...data,
            headerFields: data.headerFields ? data.headerFields : [],
            secondaryFields: data.secondaryFields ? data.secondaryFields : [],
            auxiliaryFields: data.auxiliaryFields ? data.auxiliaryFields : [],
            icons: {
              iosIcon: data.iosIcon,
              iosIcon2X: data.iosIcon2X,
              iosIcon3X: data.iosIcon3X,
            },
            iconsbackGround: {
              iosBackground: data.iosBackground,
              iosBackground2X: data.iosBackground2X,
              iosBackground3X: data.iosBackground3X,
            },
            iconsLogo: {
              iosLogo: data.iosLogo,
              iosLogo2X: data.iosLogo2X,
              iosLogo3X: data.iosLogo3X,
            },
            iconsStrip: {
              iosStrip: data.iosStrip,
              iosStrip2X: data.iosStrip2X,
              iosStrip3X: data.iosStrip3X,
            },
            iconsThumbnail: {
              iosThumbnail: data.iosThumbnail,
              iosThumbnail2X: data.iosThumbnail2X,
              iosThumbnail3X: data.iosThumbnail3X,
            },
            iconFooter: {
              iosFooter: data.iosFooter,
              iosFooter2X: data.iosFooter2X,
              iosFooter3X: data.iosFooter3X,
            },
          })
        } else return
      } catch (e) {
      } finally {
        loadingEnd(PassLoadingTypes.GET_ONE)
      }
    })()
  }, [loadingStart, loadingEnd, passId])

  const renderIcons = () => {
    const { icons } = state

    const iconsElements = []

    const iconsStateKeys = Object.keys(icons)

    iconsStateKeys.forEach(key => {
      const input_ref = createRef()

      const iconsStatePropName = key

      iconsElements.push(
        <div
          key={iconsStatePropName}
          className='pass-editor-icons_item-wrapper'
        >
          <div
            className='pass-editor-icons_item-preview-wrapper'
            onClick={() => {
              input_ref?.current?.click()
            }}
          >
            {icons[iconsStatePropName] ? (
              <>
                <div className='pass-editor-icons_item-label'>
                  {iconsStatePropName}
                </div>
                <span className='pass-editor-icons_item-preview'>
                  <img
                    src={`data:image/png;base64, ${icons[iconsStatePropName]}`}
                    alt=''
                  />
                </span>
                <div
                  className='pass-editor-icons_item-delete'
                  onClick={e => {
                    e.stopPropagation()
                    setState(oldState => {
                      return {
                        ...oldState,
                        icons: {
                          ...oldState.icons,
                          [iconsStatePropName]: '',
                        },
                      }
                    })
                  }}
                >
                  <DeleteIcon />
                </div>
              </>
            ) : (
              <div className='pass-editor-icons_item-preview pass-editor-icons_item-preview--empty'>
                <div className='pass-editor-icons_item-preview-label'>
                  Upload file
                  <br /> <span>{iconsStatePropName}</span>
                </div>
              </div>
            )}
          </div>

          <input
            className='pass-editor-icons_item-controller'
            type='file'
            name='file'
            accept='image/png, image/jpeg'
            ref={input_ref}
            onChange={e1 => {
              const file = e1.target.files[0]
              const reader = new FileReader()
              reader.onload = async e2 => {
                const { result } = e2.target
                const fileResult = result
                  .toString()
                  .replace('data:image/jpeg;base64,', '')
                  .replace('data:image/png;base64,', '')
                setState(oldState => {
                  return {
                    ...oldState,
                    icons: {
                      ...oldState.icons,
                      [iconsStatePropName]: fileResult,
                    },
                  }
                })
              }
              reader.onerror = e2 => {
                console.error(e2)
              }
              if (file) {
                reader.readAsDataURL(file)
              }
            }}
          />
        </div>,
      )
    })

    return iconsElements
  }

  const renderBackGround = () => {
    const { iconsbackGround } = state

    const iconsBackGroundElements = []

    const iconsStateKeys = Object.keys(iconsbackGround)

    iconsStateKeys.forEach(key => {
      const input_ref = createRef()

      const iconsStatePropName = key

      iconsBackGroundElements.push(
        <div
          key={iconsStatePropName}
          className='pass-editor-icons_item-wrapper'
        >
          <div
            className='pass-editor-icons_item-preview-wrapper'
            onClick={() => {
              input_ref?.current?.click()
            }}
          >
            {iconsbackGround[iconsStatePropName] ? (
              <>
                <div className='pass-editor-icons_item-label'>
                  {iconsStatePropName}
                </div>
                <span className='pass-editor-icons_item-preview'>
                  <img
                    src={`data:image/png;base64, ${iconsbackGround[iconsStatePropName]}`}
                    alt=''
                  />
                </span>
                <div
                  className='pass-editor-icons_item-delete'
                  onClick={e => {
                    e.stopPropagation()
                    setState(oldState => {
                      return {
                        ...oldState,
                        iconsbackGround: {
                          ...oldState.iconsbackGround,
                          [iconsStatePropName]: '',
                        },
                      }
                    })
                  }}
                >
                  <DeleteIcon />
                </div>
              </>
            ) : (
              <div className='pass-editor-icons_item-preview pass-editor-icons_item-preview--empty'>
                <div className='pass-editor-icons_item-preview-label'>
                  Upload file
                  <br /> <span>{iconsStatePropName}</span>
                </div>
              </div>
            )}
          </div>

          <input
            className='pass-editor-icons_item-controller'
            type='file'
            name='file'
            accept='image/png, image/jpeg'
            ref={input_ref}
            onChange={e1 => {
              const file = e1.target.files[0]
              const reader = new FileReader()
              reader.onload = async e2 => {
                const { result } = e2.target
                const fileResult = result
                  .toString()
                  .replace('data:image/jpeg;base64,', '')
                  .replace('data:image/png;base64,', '')
                setState(oldState => {
                  return {
                    ...oldState,
                    iconsbackGround: {
                      ...oldState.iconsbackGround,
                      [iconsStatePropName]: fileResult,
                    },
                  }
                })
              }
              reader.onerror = e2 => {
                console.error(e2)
              }
              if (file) {
                reader.readAsDataURL(file)
              }
            }}
          />
        </div>,
      )
    })

    return iconsBackGroundElements
  }

  const renderIconLogo = () => {
    const { iconsLogo } = state

    const iconsLogoElements = []

    const iconsStateKeys = Object.keys(iconsLogo)

    iconsStateKeys.forEach(key => {
      const input_ref = createRef()

      const iconsStatePropName = key

      iconsLogoElements.push(
        <div
          key={iconsStatePropName}
          className='pass-editor-icons_item-wrapper'
        >
          <div
            className='pass-editor-icons_item-preview-wrapper'
            onClick={() => {
              input_ref?.current?.click()
            }}
          >
            {iconsLogo[iconsStatePropName] ? (
              <>
                <div className='pass-editor-icons_item-label'>
                  {iconsStatePropName}
                </div>
                <span className='pass-editor-icons_item-preview'>
                  <img
                    src={`data:image/png;base64, ${iconsLogo[iconsStatePropName]}`}
                    alt=''
                  />
                </span>
                <div
                  className='pass-editor-icons_item-delete'
                  onClick={e => {
                    e.stopPropagation()
                    setState(oldState => {
                      return {
                        ...oldState,
                        iconsLogo: {
                          ...oldState.iconsLogo,
                          [iconsStatePropName]: '',
                        },
                      }
                    })
                  }}
                >
                  <DeleteIcon />
                </div>
              </>
            ) : (
              <div className='pass-editor-icons_item-preview pass-editor-icons_item-preview--empty'>
                <div className='pass-editor-icons_item-preview-label'>
                  Upload file
                  <br /> <span>{iconsStatePropName}</span>
                </div>
              </div>
            )}
          </div>

          <input
            className='pass-editor-icons_item-controller'
            type='file'
            name='file'
            accept='image/png, image/jpeg'
            ref={input_ref}
            onChange={e1 => {
              const file = e1.target.files[0]
              const reader = new FileReader()
              reader.onload = async e2 => {
                const { result } = e2.target
                const fileResult = result
                  .toString()
                  .replace('data:image/jpeg;base64,', '')
                  .replace('data:image/png;base64,', '')
                setState(oldState => {
                  return {
                    ...oldState,
                    iconsLogo: {
                      ...oldState.iconsLogo,
                      [iconsStatePropName]: fileResult,
                    },
                  }
                })
              }
              reader.onerror = e2 => {
                console.error(e2)
              }
              if (file) {
                reader.readAsDataURL(file)
              }
            }}
          />
        </div>,
      )
    })

    return iconsLogoElements
  }

  const renderIconStrip = () => {
    const { iconsStrip } = state

    const iconsStripElements = []

    const iconsStateKeys = Object.keys(iconsStrip)

    iconsStateKeys.forEach(key => {
      const input_ref = createRef()

      const iconsStatePropName = key

      iconsStripElements.push(
        <div
          key={iconsStatePropName}
          className='pass-editor-icons_item-wrapper'
        >
          <div
            className='pass-editor-icons_item-preview-wrapper'
            onClick={() => {
              input_ref?.current?.click()
            }}
          >
            {iconsStrip[iconsStatePropName] ? (
              <>
                <div className='pass-editor-icons_item-label'>
                  {iconsStatePropName}
                </div>
                <span className='pass-editor-icons_item-preview'>
                  <img
                    src={`data:image/png;base64, ${iconsStrip[iconsStatePropName]}`}
                    alt=''
                  />
                </span>
                <div
                  className='pass-editor-icons_item-delete'
                  onClick={e => {
                    e.stopPropagation()
                    setState(oldState => {
                      return {
                        ...oldState,
                        iconsStrip: {
                          ...oldState.iconsStrip,
                          [iconsStatePropName]: '',
                        },
                      }
                    })
                  }}
                >
                  <DeleteIcon />
                </div>
              </>
            ) : (
              <div className='pass-editor-icons_item-preview pass-editor-icons_item-preview--empty'>
                <div className='pass-editor-icons_item-preview-label'>
                  Upload file
                  <br /> <span>{iconsStatePropName}</span>
                </div>
              </div>
            )}
          </div>

          <input
            className='pass-editor-icons_item-controller'
            type='file'
            name='file'
            accept='image/png, image/jpeg'
            ref={input_ref}
            onChange={e1 => {
              const file = e1.target.files[0]
              const reader = new FileReader()
              reader.onload = async e2 => {
                const { result } = e2.target
                const fileResult = result
                  .toString()
                  .replace('data:image/jpeg;base64,', '')
                  .replace('data:image/png;base64,', '')
                setState(oldState => {
                  return {
                    ...oldState,
                    iconsStrip: {
                      ...oldState.iconsStrip,
                      [iconsStatePropName]: fileResult,
                    },
                  }
                })
              }
              reader.onerror = e2 => {
                console.error(e2)
              }
              if (file) {
                reader.readAsDataURL(file)
              }
            }}
          />
        </div>,
      )
    })

    return iconsStripElements
  }

  const renderIconThumbnail = () => {
    const { iconsThumbnail } = state

    const iconsThumbnailElements = []

    const iconsStateKeys = Object.keys(iconsThumbnail)

    iconsStateKeys.forEach(key => {
      const input_ref = createRef()

      const iconsStatePropName = key

      iconsThumbnailElements.push(
        <div
          key={iconsStatePropName}
          className='pass-editor-icons_item-wrapper'
        >
          <div
            className='pass-editor-icons_item-preview-wrapper'
            onClick={() => {
              input_ref?.current?.click()
            }}
          >
            {iconsThumbnail[iconsStatePropName] ? (
              <>
                <div className='pass-editor-icons_item-label'>
                  {iconsStatePropName}
                </div>
                <span className='pass-editor-icons_item-preview'>
                  <img
                    src={`data:image/png;base64, ${iconsThumbnail[iconsStatePropName]}`}
                    alt=''
                  />
                </span>
                <div
                  className='pass-editor-icons_item-delete'
                  onClick={e => {
                    e.stopPropagation()
                    setState(oldState => {
                      return {
                        ...oldState,
                        iconsThumbnail: {
                          ...oldState.iconsThumbnail,
                          [iconsStatePropName]: '',
                        },
                      }
                    })
                  }}
                >
                  <DeleteIcon />
                </div>
              </>
            ) : (
              <div className='pass-editor-icons_item-preview pass-editor-icons_item-preview--empty'>
                <div className='pass-editor-icons_item-preview-label'>
                  Upload file
                  <br /> <span>{iconsStatePropName}</span>
                </div>
              </div>
            )}
          </div>

          <input
            className='pass-editor-icons_item-controller'
            type='file'
            name='file'
            accept='image/png, image/jpeg'
            ref={input_ref}
            onChange={e1 => {
              const file = e1.target.files[0]
              const reader = new FileReader()
              reader.onload = async e2 => {
                const { result } = e2.target
                const fileResult = result
                  .toString()
                  .replace('data:image/jpeg;base64,', '')
                  .replace('data:image/png;base64,', '')
                setState(oldState => {
                  return {
                    ...oldState,
                    iconsThumbnail: {
                      ...oldState.iconsThumbnail,
                      [iconsStatePropName]: fileResult,
                    },
                  }
                })
              }
              reader.onerror = e2 => {
                console.error(e2)
              }
              if (file) {
                reader.readAsDataURL(file)
              }
            }}
          />
        </div>,
      )
    })

    return iconsThumbnailElements
  }

  const renderIconFooter = () => {
    const { iconFooter } = state

    const iconFooterElements = []

    const iconsStateKeys = Object.keys(iconFooter)

    iconsStateKeys.forEach(key => {
      const input_ref = createRef()

      const iconsStatePropName = key

      iconFooterElements.push(
        <div
          key={iconsStatePropName}
          className='pass-editor-icons_item-wrapper'
        >
          <div
            className='pass-editor-icons_item-preview-wrapper'
            onClick={() => {
              input_ref?.current?.click()
            }}
          >
            {iconFooter[iconsStatePropName] ? (
              <>
                <div className='pass-editor-icons_item-label'>
                  {iconsStatePropName}
                </div>
                <span className='pass-editor-icons_item-preview'>
                  <img
                    src={`data:image/png;base64, ${iconFooter[iconsStatePropName]}`}
                    alt=''
                  />
                </span>
                <div
                  className='pass-editor-icons_item-delete'
                  onClick={e => {
                    e.stopPropagation()
                    setState(oldState => {
                      return {
                        ...oldState,
                        iconFooter: {
                          ...oldState.iconFooter,
                          [iconsStatePropName]: '',
                        },
                      }
                    })
                  }}
                >
                  <DeleteIcon />
                </div>
              </>
            ) : (
              <div className='pass-editor-icons_item-preview pass-editor-icons_item-preview--empty'>
                <div className='pass-editor-icons_item-preview-label'>
                  Upload file
                  <br /> <span>{iconsStatePropName}</span>
                </div>
              </div>
            )}
          </div>

          <input
            className='pass-editor-icons_item-controller'
            type='file'
            name='file'
            accept='image/png, image/jpeg'
            ref={input_ref}
            onChange={e1 => {
              const file = e1.target.files[0]
              const reader = new FileReader()
              reader.onload = async e2 => {
                const { result } = e2.target
                const fileResult = result
                  .toString()
                  .replace('data:image/jpeg;base64,', '')
                  .replace('data:image/png;base64,', '')
                setState(oldState => {
                  return {
                    ...oldState,
                    iconFooter: {
                      ...oldState.iconFooter,
                      [iconsStatePropName]: fileResult,
                    },
                  }
                })
              }
              reader.onerror = e2 => {
                console.error(e2)
              }
              if (file) {
                reader.readAsDataURL(file)
              }
            }}
          />
        </div>,
      )
    })

    return iconFooterElements
  }

  const {
    backgroundColor,
    description,
    foregroundColor,
    labelColor,
    logoText,
    organizationName,
    style,
    title,
    url,
    barcode,
    barcodeType,
    gaTrackingId,
    id,
    identifier,
    headerFields,
    primaryField,
    secondaryFields,
    auxiliaryFields,
  } = state

  const changeRowInObject = array => {
    let newArray = [...array]
    newArray = newArray.map((item, i) => {
      return {
        ...item,
        row: i,
      }
    })
    return newArray
  }

  const changeAttributedValueInObject = stateProp => {
    if (!state[stateProp]) return []
    let newArray = [...state[stateProp]]
    newArray = newArray.map((item, i) => {
      return {
        ...item,
        attributedValue: item?.value,
      }
    })
    return newArray
  }

  const addFields = stateProp => {
    setState(oldState => ({
      ...oldState,
      [stateProp]: [
        ...oldState[stateProp],
        {
          value: '',
          label: '',
          attributedValue: '',
          row: state[stateProp] ? state[stateProp].length : 0,
        },
      ],
    }))
  }

  const deleteField = (stateProp, index) => {
    const newArray = [
      ...state[stateProp].slice(0, index),
      ...state[stateProp].slice(index + 1),
    ]

    setState(oldState => {
      return {
        ...oldState,
        [stateProp]: changeRowInObject(newArray),
      }
    })
  }

  const formSubmitHandle = async e => {
    e.preventDefault()

    const headerFields = changeAttributedValueInObject('headerFields')
    const secondaryFields = changeAttributedValueInObject('secondaryFields')
    const auxiliaryFields = changeAttributedValueInObject('auxiliaryFields')

    const data = {
      ...state,
      ...state.icons,
      ...state.iconsbackGround,
      ...state.iconsLogo,
      ...state.iconsStrip,
      ...state.iconsThumbnail,
      ...state.iconFooter,
      companyId: authUser.companyId,
      style: +state.style,
      headerFields,
      secondaryFields,
      auxiliaryFields,
      primaryField: {
        ...primaryField,
        attributedValue: primaryField?.value,
      },
      status: 1,
    }

    delete data.icons
    delete data.iconsbackGround
    delete data.iconsLogo
    delete data.iconsStrip
    delete data.iconsThumbnail
    delete data.iconFooter

    if (id === -1) {
      await createPass(data)
      history.push('/admin/passes')
      return
    }
    await updatePass(data)
    history.push('/admin/passes')
    return
  }

  return (
    <div className='pass-editor'>
      <header className='pass-editor_header'>
        <button
          className='pass-editor_close'
          onClick={() => {
            history.push('/admin/passes')
          }}
        >
          <CloseIcon />
        </button>
      </header>
      <form
        className='pass-editor_controllers-wrapper'
        onSubmit={formSubmitHandle}
      >
        <div className='pass-editor_controllers-items-wrapper'>
          <div className='pass-editor_controllers-item-name'>Data</div>
          <div className='pass-editor_controllers-items-inner'>
            <label className='pass-editor_controllers-item'>
              <div className='pass-editor_controllers-item-label'>Title</div>
              <input
                type='text'
                value={title}
                onChange={e => {
                  setState(oldS => ({
                    ...oldS,
                    title: e.target.value,
                  }))
                }}
              />
            </label>
            <label className='pass-editor_controllers-item'>
              <div className='pass-editor_controllers-item-label'>
                Description
              </div>
              <input
                type='text'
                value={description}
                onChange={e => {
                  setState(oldS => ({
                    ...oldS,
                    description: e.target.value,
                  }))
                }}
              />
            </label>
            <label className='pass-editor_controllers-item'>
              <div className='pass-editor_controllers-item-label'>
                Identifier
              </div>
              <input
                type='text'
                value={identifier}
                onChange={e => {
                  setState(oldS => ({
                    ...oldS,
                    identifier: e.target.value,
                  }))
                }}
              />
            </label>
            <label className='pass-editor_controllers-item'>
              <div className='pass-editor_controllers-item-label'>
                Logo text
              </div>
              <input
                type='text'
                value={logoText}
                onChange={e => {
                  setState(oldS => ({
                    ...oldS,
                    logoText: e.target.value,
                  }))
                }}
              />
            </label>
            <label className='pass-editor_controllers-item'>
              <div className='pass-editor_controllers-item-label'>
                Organization name
              </div>
              <input
                type='text'
                value={organizationName}
                onChange={e => {
                  setState(oldS => ({
                    ...oldS,
                    organizationName: e.target.value,
                  }))
                }}
              />
            </label>
            <label className='pass-editor_controllers-item'>
              <div className='pass-editor_controllers-item-label'>URL</div>
              <input
                type='text'
                value={url}
                onChange={e => {
                  setState(oldS => ({
                    ...oldS,
                    url: e.target.value,
                  }))
                }}
                placeholder='url'
              />
            </label>
            <label className='pass-editor_controllers-item'>
              <div className='pass-editor_controllers-item-label'>Barcode</div>
              <input
                type='text'
                value={barcode}
                onChange={e => {
                  setState(oldS => ({
                    ...oldS,
                    barcode: e.target.value,
                  }))
                }}
              />
            </label>
            <label className='pass-editor_controllers-item'>
              <div className='pass-editor_controllers-item-label'>
                Barcode type
              </div>
              <select
                value={barcodeType}
                onChange={e => {
                  setState(oldS => ({ ...oldS, barcodeType: +e.target.value }))
                }}
              >
                {Object.keys(BARCODE_TYPE).map(key => {
                  return (
                    <option key={key} value={key}>
                      {BARCODE_TYPE[key]}
                    </option>
                  )
                })}
              </select>
            </label>
            <label className='pass-editor_controllers-item'>
              <div className='pass-editor_controllers-item-label'>
                Google Analytics Tracking Id
              </div>
              <input
                type='text'
                value={gaTrackingId}
                onChange={e => {
                  setState(oldS => ({
                    ...oldS,
                    gaTrackingId: e.target.value,
                  }))
                }}
              />
            </label>
            <label className='pass-editor_controllers-item'>
              <div className='pass-editor_controllers-item-label'>Style</div>
              <select
                value={style}
                onChange={e => {
                  setState(oldS => ({ ...oldS, style: e.target.value }))
                }}
              >
                {Object.keys(PASS_STYLE).map(key => {
                  return (
                    <option key={key} value={key}>
                      {PASS_STYLE[key]}
                    </option>
                  )
                })}
              </select>
            </label>
          </div>
        </div>
        <div className='pass-editor_controllers-items-wrapper '>
          {/* <legend className='pass-editor_controllers-items-label'>
            Primary
          </legend> */}
          <div className='pass-editor_controllers-item-name'>
            Primary Field
          </div>
          <div className='pass-editor_controllers-items-inner primary-field'>
            <label className='pass-editor_controllers-item-l'>
              <div className='pass-editor_controllers-item-label'>Value</div>
              <input
                maxLength={150}
                type='text'
                value={primaryField?.value}
                onChange={e => {
                  setState(oldS => ({
                    ...oldS,
                    primaryField: {
                      ...oldS.primaryField,
                      value: e.target.value,
                    },
                  }))
                }}
              />
            </label>
            <label className='pass-editor_controllers-item-l'>
              <div className='pass-editor_controllers-item-label'>Label</div>
              <input
                type='text'
                value={primaryField?.label}
                onChange={e => {
                  setState(oldS => ({
                    ...oldS,
                    primaryField: {
                      ...oldS.primaryField,
                      label: e.target.value,
                    },
                  }))
                }}
              />
            </label>
          </div>
        </div>
        <div className='pass-editor_controllers-items-wrapper'>
          {/* <legend className='pass-editor_controllers-items-label'>
            Header
          </legend> */}
          <div className='pass-editor_controllers-item-name'>
            Header
          </div>

          <div className='pass-editor_block'>
            {headerFields?.map((fieldsObj, i) => {
              return (
                <div key={i} className='pass-editor_block-item-wrapper'>
                  <div>
                    <div
                      // className='pass-editor_delete-btn'
                      className='pass-editor_close-x'
                      onClick={() => {
                        deleteField('headerFields', i)
                      }}
                    >
                      <DeleteIcon />
                    </div>

                  </div>
                  <div className='pass-editor_block-item-inner'>
                    <div className='pass-editor_block-item-label'>Label</div>
                    <input
                      type='text'
                      value={fieldsObj?.label}
                      onChange={e => {
                        let newHeaderFields = [...headerFields]
                        const newFieldsObj = { ...headerFields[i] }
                        newFieldsObj.label = e.target.value
                        newHeaderFields[i] = newFieldsObj
                        setState(oldS => ({
                          ...oldS,
                          headerFields: newHeaderFields,
                        }))
                      }}
                    />
                  </div>
                  <div className='pass-editor_block-item-inner'>
                    <div className='pass-editor_block-item-label'>Value</div>
                    <input
                      maxLength={150}
                      type='text'
                      value={fieldsObj?.value}
                      onChange={e => {
                        let newHeaderFields = [...headerFields]
                        const newFieldsObj = { ...headerFields[i] }
                        newFieldsObj.value = e.target.value
                        newHeaderFields[i] = newFieldsObj
                        setState(oldS => ({
                          ...oldS,
                          headerFields: newHeaderFields,
                        }))
                      }}
                    />
                  </div>
                </div>
              )
            })}
            {/* <button
              className='pass-editor_add-btn'
              type='button'
              onClick={() => {
                addFields('headerFields')
              }}
            >
              +
            </button> */}
            <div className='pass-editor_add-btn'>
              <div
                className='pass-editor_add-btn-plus'
                type='button'
                onClick={() => {
                  addFields('headerFields')
                }}
              >
                <AddIcon />
              </div>
            </div>
          </div>
        </div>
        <div className='pass-editor_controllers-items-wrapper'>
          <div className='pass-editor_controllers-item-name'>
            Secondary
          </div>

          <div className='pass-editor_block'>
            {secondaryFields?.map((fieldsObj, i) => {
              return (
                <div key={i} className='pass-editor_block-item-wrapper'>
                  <div
                    // className='pass-editor_delete-btn'
                    className='pass-editor_close-x'
                    onClick={() => {
                      deleteField('secondaryFields', i)
                    }}
                  >
                    <DeleteIcon />
                  </div>
                  <div className='pass-editor_block-item-inner'>
                    <div className='pass-editor_block-item-label'>Label</div>
                    <input
                      type='text'
                      value={fieldsObj?.label}
                      onChange={e => {
                        let newSecondaryFields = [...secondaryFields]
                        const newFieldsObj = { ...secondaryFields[i] }
                        newFieldsObj.label = e.target.value
                        newSecondaryFields[i] = newFieldsObj
                        setState(oldS => ({
                          ...oldS,
                          secondaryFields: newSecondaryFields,
                        }))
                      }}
                    />
                  </div>
                  <div className='pass-editor_block-item-inner'>
                    <div className='pass-editor_block-item-label'>Value</div>
                    <input
                      maxLength={150}
                      type='text'
                      value={fieldsObj?.value}
                      onChange={e => {
                        let newSecondaryFields = [...secondaryFields]
                        const newFieldsObj = { ...secondaryFields[i] }
                        newFieldsObj.value = e.target.value
                        newSecondaryFields[i] = newFieldsObj
                        setState(oldS => ({
                          ...oldS,
                          secondaryFields: newSecondaryFields,
                        }))
                      }}
                    />
                  </div>
                </div>
              )
            })}
            {/* <button
              className='pass-editor_add-btn'
              type='button'
              onClick={() => {
                addFields('secondaryFields')
              }}
            >
              +
            </button> */}
            <div className='pass-editor_add-btn'>
              <div
                className='pass-editor_add-btn-plus'
                type='button'
                onClick={() => {
                  addFields('secondaryFields')
                }}
              >
                <AddIcon />
              </div>
            </div>
          </div>
        </div>
        <div className='pass-editor_controllers-items-wrapper'>
          {/* <legend className='pass-editor_controllers-items-label'>
            Auxiliary
          </legend> */}
          <div className='pass-editor_controllers-item-name'>
            Auxiliary
          </div>

          <div className='pass-editor_block'>
            {auxiliaryFields?.map((fieldsObj, i) => {
              return (
                <div key={i} className='pass-editor_block-item-wrapper'>
                  <div
                    // className='pass-editor_delete-btn'
                    className='pass-editor_close-x'
                    onClick={() => {
                      deleteField('auxiliaryFields', i)
                    }}
                  >
                    <DeleteIcon />
                  </div>
                  <div className='pass-editor_block-item-inner'>
                    <div className='pass-editor_block-item-label'>Label</div>
                    <input
                      type='text'
                      value={fieldsObj?.label}
                      onChange={e => {
                        let newAuxiliaryFields = [...auxiliaryFields]
                        const newFieldsObj = { ...auxiliaryFields[i] }
                        newFieldsObj.label = e.target.value
                        newAuxiliaryFields[i] = newFieldsObj
                        setState(oldS => ({
                          ...oldS,
                          auxiliaryFields: newAuxiliaryFields,
                        }))
                      }}
                    />
                  </div>
                  <div className='pass-editor_block-item-inner'>
                    <div className='pass-editor_block-item-label'>Value</div>
                    <input
                      maxLength={150}
                      type='text'
                      value={fieldsObj?.value}
                      onChange={e => {
                        let newAuxiliaryFields = [...auxiliaryFields]
                        const newFieldsObj = { ...auxiliaryFields[i] }
                        newFieldsObj.value = e.target.value
                        newAuxiliaryFields[i] = newFieldsObj
                        setState(oldS => ({
                          ...oldS,
                          auxiliaryFields: newAuxiliaryFields,
                        }))
                      }}
                    />
                  </div>
                </div>
              )
            })}
            {/* <button
              className='pass-editor_add-btn'
              type='button'
              onClick={() => {
                addFields('auxiliaryFields')
              }}
            >
              +
            </button> */}

            <div className='pass-editor_add-btn'>
              <div
                className='pass-editor_add-btn-plus'
                type='button'
                onClick={() => {
                  addFields('auxiliaryFields')
                }}
              >
                <AddIcon />
              </div>
            </div>
          </div>
        </div>
        <div className='pass-editor_controllers-items-wrapper'>
          <div className='pass-editor_controllers-items-label'>
            Colors
          </div>
          <div className='pass-editor_controllers-items-inner'>
            <label className='pass-editor_controllers-item'>
              <div className='pass-editor_controllers-item-label'>
                Background color
              </div>
              <input
                type='color'
                value={backgroundColor}
                onChange={e => {
                  setState(oldS => ({
                    ...oldS,
                    backgroundColor: e.target.value,
                  }))
                }}
                placeholder='backgroundColor'
              />
            </label>
            <label className='pass-editor_controllers-item'>
              <div className='pass-editor_controllers-item-label'>
                Label color
              </div>
              <input
                type='color'
                value={labelColor}
                onChange={e => {
                  setState(oldS => ({
                    ...oldS,
                    labelColor: e.target.value,
                  }))
                }}
              />
            </label>
            <label className='pass-editor_controllers-item'>
              <div className='pass-editor_controllers-item-label'>
                Foreground color
              </div>
              <input
                type='color'
                value={foregroundColor}
                onChange={e => {
                  setState(oldS => ({
                    ...oldS,
                    foregroundColor: e.target.value,
                  }))
                }}
              />
            </label>
          </div>
        </div>
        <div className='pass-editor_controllers-items-wrapper pass-editor-icons'>
          <div className='pass-editor_controllers-items-wrapper-div'>
            <div className='pass-editor_controllers-items-label-f'>Icons</div>
            <div className='pass-editor_controllers-items-inner-f'>
              {renderIcons()}
            </div>
          </div>
          <div className='pass-editor_controllers-items-wrapper-div'>
            <div className='pass-editor_controllers-items-label-f'>BackGround</div>
            <div className='pass-editor_controllers-items-inner-f'>
              {renderBackGround()}
            </div>
          </div>
          <div className='pass-editor_controllers-items-wrapper-div'>
            <div className='pass-editor_controllers-items-label-f'>Logo</div>
            <div className='pass-editor_controllers-items-inner-f'>
              {renderIconLogo()}
            </div>
          </div>
          <div className='pass-editor_controllers-items-wrapper-div'>
            <div className='pass-editor_controllers-items-label-f'>Strip</div>
            <div className='pass-editor_controllers-items-inner-f'>
              {renderIconStrip()}
            </div>
          </div>
          <div className='pass-editor_controllers-items-wrapper-div'>
            <div className='pass-editor_controllers-items-label-f'>Thumbnail</div>
            <div className='pass-editor_controllers-items-inner-f'>
              {renderIconThumbnail()}
            </div>
          </div>
          <div className='pass-editor_controllers-items-wrapper-div'>
            <div className='pass-editor_controllers-items-label-f'>Footer</div>
            <div className='pass-editor_controllers-items-inner-f'>
              {renderIconFooter()}
            </div>
          </div>
        </div>
        <div className='pass-editor_submit-btn-div'>
          <button className='admin-btn pass-editor_submit-btn'>Save</button>
        </div>
      </form>
    </div>
  )
}

export default PassEditor
