import React, { useState } from 'react'
import { Header, LeftMenu } from '../components'
import { useHistory } from 'react-router-dom'

import { ReactComponent as CloseIcon } from '../assets/close-icon.svg'

const AdminLayout = ({ children }) => {
  const [leftColumnShowToggle, setLeftColumnShowToggle] = useState(true)
  const history = useHistory()
  return (
    <>
      <Header setLeftColumnShowToggle={setLeftColumnShowToggle} />
      <div className='admin-container'>
        {leftColumnShowToggle && (
          <aside className='left-column'>
            <LeftMenu />
          </aside>
        )}
        <main className='admin-content-wrapper'>
          <div className='admin-content-inner'>{children}</div>
        </main>
      </div>
    </>

  )
}

export default AdminLayout
