import React, { useEffect } from 'react'
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { privateRoutes, publicRoutes } from '../routes'
import { useActions } from '../hooks/useActions'
import { ADMIN, AUTH_ROUTE } from '../utils/consts'

const AppRouter = () => {
  const location = useLocation()
  const history = useHistory()

  const auth = useSelector(({ auth }) => {
    return auth
  })

  const { checkAuth } = useActions()

  useEffect(() => {
    ;(async () => {
      if (localStorage.getItem('token')) {
        await checkAuth()
        history.push(location.pathname)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Switch>
      {auth?.isAuth &&
        privateRoutes.map(({ path, Component }) => (
          <Switch key={path}>
            <Route path={path} component={Component} exact />
            <Redirect to={ADMIN} />
          </Switch>
        ))}
      {publicRoutes.map(({ path, Component }) => (
        <Route key={path} path={path} component={Component} exact />
      ))}
      <Redirect to={AUTH_ROUTE} />
    </Switch>
  )
}

export default AppRouter
