import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ListContent } from '.'
import { useActions } from '../hooks/useActions'
import { CompanyLoadingTypes } from '../redux/reducers/company/types'
import CompanyService from '../services/company'
import {
  TABLE_LIST_ITEM_VALUE,
  TABLE_LIST_ITEM_VALUE__ID,
} from '../utils/consts'
import Users from './Users'

import { ReactComponent as EditIcon } from '../assets/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../assets/delete-icon.svg'
import Modal from './Modal'

const CompaniesUsers = () => {
  const {
    company: { companies },
  } = useSelector(({ company, user }) => {
    return { company, user }
  })

  const [selectedCompany, setSelectedCompany] = useState('')

  const [confirmDeleteCompanyModalActive, setConfirmDeleteCompanyModalActive] =
    useState(false)

  const [deleteCandidateCompany, setDeleteCandidateCompany] = useState({})

  const [modalActive, setModalActive] = useState()
  const [companyData, setCompanyData] = useState({
    name: '',
    description: '',
    state: 1,
    id: -1,
  })

  const {
    getCompanyList,
    getUsersList,
    createUser,
    updateUser,
    deleteUser,
    loadingStart,
    loadingEnd,
    createCompany,
    updateCompany,
    deleteCompany,
  } = useActions()

  const userActions = useMemo(
    () => ({
      getData: () => {
        getUsersList({
          companyId: selectedCompany,
        })
      },
      createData: data => {
        createUser({ companyId: selectedCompany, ...data, role: 1 })
      },
      updateData: data => {
        updateUser({ companyId: selectedCompany, ...data, role: 1 })
      },
      deleteData: id => {
        deleteUser(id)
      },
    }),
    [getUsersList, selectedCompany, createUser, updateUser, deleteUser],
  )

  const getCompanies = useCallback(
    async page => {
      if (!page) page = 0

      await getCompanyList({})
    },
    [getCompanyList],
  )

  const renderItemValue = item => {
    return (
      <div
        className={
          selectedCompany === item.id
            ? 'table-list_row table-list_row--selected'
            : 'table-list_row'
        }
        onClick={() => {
          if (selectedCompany === item.id) {
            setSelectedCompany('')
            return
          }
          setSelectedCompany(item.id)
        }}
      >
        <div className={TABLE_LIST_ITEM_VALUE__ID}>{item.id}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>{item.name}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>{item.description}</div>
        <div className={TABLE_LIST_ITEM_VALUE}>
          <button
            className='table-list_item-btn'
            onClick={async e => {
              e.stopPropagation()

              try {
                loadingStart(CompanyLoadingTypes.GET_ONE)

                const { data: company } = await CompanyService.getCompanyById(
                  item.id,
                )

                setCompanyData({ ...company })
                setModalActive(true)
              } catch (e) {
              } finally {
                loadingEnd(CompanyLoadingTypes.GET_ONE)
              }
            }}
          >
            <EditIcon />
          </button>
          <button
            className='table-list_item-btn'
            onClick={e => {
              e.stopPropagation()
              setDeleteCandidateCompany(item)
              setConfirmDeleteCompanyModalActive(true)
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (!companies.length) return
    setSelectedCompany(companies?.[0]?.id)
  }, [companies])

  useEffect(() => {
    ;(async () => {
      await getCompanies()
    })()
  }, [getCompanies])

  const renderCompanyModalForm = () => {
    if (companyData.id === -1) return true
    return false
  }

  const createBtn = () => {
    setCompanyData({
      name: '',
      description: '',
      state: '1',
      id: -1,
    })
    setModalActive(true)
  }

  const { name, description } = companyData

  const formHandle = e => {
    e.preventDefault()
    if (renderCompanyModalForm()) {
      createCompany(companyData)
      setModalActive(false)
      return
    }

    updateCompany(companyData)
    setModalActive(false)
  }

  return (
    <div className='companies-users'>
      <ListContent
        title='Companies'
        columnTitles={['id', 'name', 'description', 'actions']}
        renderItemValue={renderItemValue}
        data={companies}
        createBtn={createBtn}
      />

      <Modal
        active={modalActive}
        setActive={setModalActive}
        title={renderCompanyModalForm() ? 'Create company' : 'Update company'}
      >
        <form className='companies-users-form modal-form' onSubmit={formHandle}>
          <label>
            <div>Name</div>
            <input
              type='text'
              value={name}
              onChange={e => {
                setCompanyData(oldS => ({
                  ...oldS,
                  name: e.target.value,
                }))
              }}
            />
          </label>
          <label>
            <div>Description</div>
            <input
              type='text'
              value={description}
              onChange={e => {
                setCompanyData(oldS => ({
                  ...oldS,
                  description: e.target.value,
                }))
              }}
            />
          </label>

          <button className='companies-users-form_btn admin-btn'>Save</button>
        </form>
      </Modal>
      <Modal
        confirm
        active={confirmDeleteCompanyModalActive}
        setActive={setConfirmDeleteCompanyModalActive}
        title={`Delete Company`}
        footer={
          <div className='companies-users_delete-company-footer'>
            <div className='modal-footer-buttons'>
              <button
                className='modal-footer-buttons_btn admin-btn'
                onClick={() => {
                  deleteCompany(deleteCandidateCompany.id)
                  setConfirmDeleteCompanyModalActive(false)
                }}
              >
                Ok
              </button>
              <button
                className='modal-footer-buttons_btn admin-btn'
                onClick={() => {
                  setConfirmDeleteCompanyModalActive(false)
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        }
      >
        <div className='companies-users_delete-user delete-modal-description'>
          Do you really want to delete Company
        </div>
      </Modal>

      {selectedCompany && <Users roles={false} {...userActions} />}
    </div>
  )
}

export default CompaniesUsers
