import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import Users from './Users'

const SuperUsers = () => {
  const {
    auth: { authUser },
  } = useSelector(({ auth }) => {
    return { auth }
  })

  const { getUsersList, createUser, updateUser, deleteUser } = useActions()

  const userActions = useMemo(
    () => ({
      getData: () => {
        getUsersList({ role: authUser?.role })
      },
      createData: data => {
        createUser({ ...data, role: authUser?.role })
      },
      updateData: data => {
        updateUser({ ...data, role: authUser?.role })
      },
      deleteData: id => {
        deleteUser(id)
      },
    }),
    [getUsersList, createUser, updateUser, deleteUser, authUser?.role],
  )

  return (
    <div className='super-users'>
      <Users roles={false} {...userActions} />
    </div>
  )
}

export default SuperUsers
