const CustomerActionTypes = {
  SET_CUSTOMERS: 'SET_CUSTOMERS',
  SET_ERROR: 'SET_ERROR',
  GET_CUSTOMER_COUNT: 'GET_CUSTOMER_COUNT',
  GET_CUSTOMER_TOTAL_COUNTS: 'GET_CUSTOMER_TOTAL_COUNTS',
}

export const CustomerLoadingTypes = {
  GET: 'GET',
  GET_COUNT: 'GET_COUNT',
  GET_TOTAL_COUNTS: 'GET_TOTAL_COUNTS',
}

export default CustomerActionTypes
