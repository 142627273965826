import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useActions } from '../hooks/useActions'
import { ADMIN } from '../utils/consts'

const AuthPage = () => {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')

  const { signIn } = useActions()

  const history = useHistory()

  const formSubmitHandle = async e => {
    e.preventDefault()
    await signIn(login, password)
    history.push(ADMIN)
  }

  return (
    <div className='auth-form'>
      <div className='auth-form_inner'>
        <div className='auth-form_body-wrapper'>
          <div className='auth-form_title'>Sign in</div>
          <form className='auth-form_body-inner' onSubmit={formSubmitHandle}>
            <div className='auth-form_controllers'>
              <input
                className='auth-form_name auth-form_controllers-item'
                onChange={e => setLogin(e.target.value)}
                value={login}
                type='text'
                placeholder='Email'
              />
              <input
                className='auth-form_email auth-form_controllers-item'
                onChange={e => setPassword(e.target.value)}
                value={password}
                type='password'
                placeholder='Пароль'
              />
              <button className='auth-form_controllers_item auth-form_btn admin-btn'>
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AuthPage
