import { AuthActionCreators } from './auth/action-creators'
import { UserActionCreators } from './user/action-creators'
import { CompanyActionCreators } from './company/action-creators'
import { PassActionCreators } from './pass/action-creators'
import { NotificationActionCreators } from './notification/action-creators'
import { CustomerActionCreators } from './customer/action-creators'
import { LoadingActionCreators } from './loading/action-creators'

export const allActionCreators = {
  ...AuthActionCreators,
  ...UserActionCreators,
  ...CompanyActionCreators,
  ...PassActionCreators,
  ...NotificationActionCreators,
  ...CustomerActionCreators,
  ...LoadingActionCreators,
}
