import CompanyActionTypes, { CompanyLoadingTypes } from './types'
import CompanyService from '../../../services/company'
import { LoadingActionCreators } from '../loading/action-creators'

export const CompanyActionCreators = {
  setCompanies: companies => ({
    type: CompanyActionTypes.SET_COMPANIES,
    payload: companies,
  }),

  setCompany: company => ({
    type: CompanyActionTypes.SET_COMPANY,
    payload: company,
  }),

  setError: payload => ({
    type: CompanyActionTypes.SET_ERROR,
    payload,
  }),

  _createCompany: company => ({
    type: CompanyActionTypes.CREATE_COMPANY,
    payload: company,
  }),

  _updateCompany: company => ({
    type: CompanyActionTypes.UPDATE_COMPANY,
    payload: company,
  }),

  _deleteCompany: company => ({
    type: CompanyActionTypes.DELETE_COMPANY,
    payload: company,
  }),

  createCompany: data => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(CompanyLoadingTypes.CREATE))

      const { data: company } = await CompanyService.createCompany(data)

      dispatch(LoadingActionCreators.loadingEnd(CompanyLoadingTypes.CREATE))

      dispatch(CompanyActionCreators._createCompany(company))
      dispatch(CompanyActionCreators.setError(''))
    } catch (e) {
      dispatch(CompanyActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(CompanyLoadingTypes.CREATE))
    }
  },

  updateCompany: data => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(CompanyLoadingTypes.UPDATE))

      const { data: company } = await CompanyService.updateCompany(data)

      dispatch(CompanyActionCreators._updateCompany(company))
      dispatch(CompanyActionCreators.setError(''))
    } catch (e) {
      dispatch(CompanyActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(CompanyLoadingTypes.UPDATE))
    }
  },

  deleteCompany: id => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(CompanyLoadingTypes.DELETE))

      await CompanyService.deleteCompany(id)

      dispatch(CompanyActionCreators._deleteCompany(id))
      dispatch(CompanyActionCreators.setError(''))
    } catch (e) {
      dispatch(CompanyActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(CompanyLoadingTypes.DELETE))
    }
  },

  getCompanyList: body => async dispatch => {
    try {
      dispatch(LoadingActionCreators.loadingStart(CompanyLoadingTypes.GET))

      const { data: companies } = await CompanyService.getCompanyList(body)

      dispatch(CompanyActionCreators.setCompanies(companies))
      dispatch(CompanyActionCreators.setError(''))
    } catch (e) {
      dispatch(CompanyActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(CompanyLoadingTypes.GET))
    }
  },

  getCompanyById: () => async (dispatch, getState) => {
    const {
      auth: { authUser },
    } = getState()

    try {
      dispatch(LoadingActionCreators.loadingStart(CompanyLoadingTypes.GET_ONE))

      if (authUser?.companyId) {
        const { data: company } = await CompanyService.getCompanyById(
          authUser.companyId,
        )

        dispatch(CompanyActionCreators.setCompany(company))
        dispatch(CompanyActionCreators.setError(''))
      }
    } catch (e) {
      dispatch(CompanyActionCreators.setError('Error'))
    } finally {
      dispatch(LoadingActionCreators.loadingEnd(CompanyLoadingTypes.GET_ONE))
    }
  },
}
