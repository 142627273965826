const CompanyActionTypes = {
  SET_COMPANY: 'SET_COMPANY',
  SET_COMPANIES: 'SET_COMPANIES',
  CREATE_COMPANY: 'CREATE_COMPANY',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  DELETE_COMPANY: 'DELETE_COMPANY',
  SET_ERROR: 'SET_ERROR',
}

export const CompanyLoadingTypes = {
  GET: 'GET',
  GET_ONE: 'GET_ONE',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
}

export default CompanyActionTypes
