export const AUTH_ROUTE = '/auth'
export const ADMIN = '/admin'
export const ADMIN_PATH_PARAM = '/admin/:pathParam?/:pathParam?/:id?'

export const TABLE_LIST_ITEM_VALUE = 'table-list_item-value'

export const TABLE_LIST_ITEM_VALUE__ID =
  'table-list_item-value table-list_item-value--id'

export const CUSTOMERS_DEVICES = {
  0: 'iOS',
  1: 'Android',
  2: 'PassWallet',
}

export const PASS_STYLE = {
  0: 'Generic',
  1: 'Boarding pass',
  2: 'Coupon',
  3: 'Event ticket',
  4: 'Store card',
}

export const USER_ROLES = {
  0: 'Super user',
  1: 'Admin',
  2: 'Manager',
  3: 'Pusher',
}

export const NOTIFICATION_PUSH_STATUS = {
  0: 'Created',
  1: 'Pending',
  2: 'Error',
  3: 'Sent',
  4: 'Success',
  5: 'Scheduled',
}

export const BARCODE_TYPE = {
  1: 'QR',
  2: 'PDF417',
  3: 'Aztec',
  4: 'Code128',
}
